/*md
# Product Availability

## Default state

```html_example
	<div class="b-product_availability m-instock">In Stock</div>
```
*/

.b-product_availability {
	font-size: 18px;
	letter-spacing: 0.2px;
	line-height: 26px;

	&::before {
		border-radius: 50%;
		content: '';
		display: inline-block;
		height: 14px;
		margin-right: rh(1);
		width: 14px;
	}

	&.m-instock::before {
		background: $color-product_availability-in_stock;
	}

	&.m-lowinstock::before {
		background: $color-product_availability-low_stock;
	}

	&.m-outofstock::before {
		background: $color-product_availability-out_of_stock;
	}
}
