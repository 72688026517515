/*md

# f-radio

Default radio element

## Default

```scss_example
	.f-input_radio {
		@include f-radio;
	}
```
*/

@mixin f-radio($el: root, $mod: default) {
	@if $el == root {
		.f-input_radio {
			align-items: center;
			display: flex;
			width: 100%;

			&-field {
				@include f-radio(control);
			}

			&-field:checked,
			&-field.m-checked {
				@include f-radio(control, checked);
			}

			&-label {
				@include f-label(default);
			}

			&.m-required &-label {
				@include f-label(required);
			}

			&-field:checked + &-label,
			&-field.m-checked + &-label {
				color: $color-form-input;
			}

			&-field:disabled {
				@include f-radio(control, disabled);
			}

			&-field:disabled + &-label {
				color: $color-form-input__disabled;
				pointer-events: none;
			}
		}
	}

	@if $el == control {
		@if $mod == default {
			@include f-input_reset;
			@include icon($icon_name: radio-unchecked radio-checked, $icon-width: 20px, $icon-height: 20px, $show: root);

			background: $color-form-input;
			cursor: pointer;
			height: 20px;
			margin-right: rh(2);
			width: 20px;
		}

		@if $mod == checked {
			@include icon($icon_name: radio-unchecked radio-checked, $show: 2);
		}

		@if $mod == disabled {
			background: $color-form-input__disabled;
			pointer-events: none;
		}
	}
}
