/*md
# Wishlist CTA

## Default state

```html_example
	<button class="b-add_to_wishlist" aria-label="Add to wishlist"></button>
```

## Active state

```html_example
	<button class="b-add_to_wishlist m-added" aria-label="Add to wishlist"></button>
```

## Variation with label inside - m-with_label
```html_example
	<button class="b-add_to_wishlist m-with_label" aria-label="Add to wishlist">
		<span class="b-add_to_wishlist-label">
			<span class="b-add_to_wishlist-label_text">Favorites</span>
			<span class="b-add_to_wishlist-label_text_added">Added</span>
		</span>
	</button>
```

*/

.b-add_to_wishlist {
	@mixin _active_state_styles {
		@include icon($icon_name: wishlist wishlist-active, $show: 2);

		--icon-color: #{$color-wishlist-active};

		@include media(sm) {
			height: 14px;
			width: 16px;
		}
	}

	--icon-color: #{$color-wishlist};

	cursor: pointer;

	@include icon($icon_name: wishlist wishlist-active, $icon-width: 25px, $icon-height: 22px) {
		@include media(sm) {
			height: 14px;
			width: 16px;
		}
	}

	@include hover-supported {
		&:hover::before {
			@include _active_state_styles;
		}
	}

	&-label {
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 3px;
		line-height: 24px;
		margin-left: rh(2);
		text-transform: uppercase;
	}

	&-label_text_added {
		display: none;
	}

	&.m-added::before {
		@include _active_state_styles;
	}

	&.m-added &-label_text {
		display: none;
	}

	&.m-added &-label_text_added {
		@include force_repaint;

		display: inline;
	}

	&.m-with_label {
		align-items: center;
		display: flex;
		grid-area: wishlist;
		justify-content: center;
		margin: rh(3 0 3 4);

		@include media(md-down) {
			margin: rh(6 0 0);
			width: 100%;
		}

		&::before {
			@include media(md-up) {
				height: 27px;
				width: 24px;
			}
		}
	}
}
