/*md
@no-stat

# Lines clamp with lines reserve

This mixin is useful for adding ... for text with more than 1 line and reserve space

## Usage

```scss
.component {
	@include lines-clamp-reserve;
}
```
*/

@mixin lines-clamp-reserve($lines: 3, $line-height: 1.2) {
	@include lines-clamp($lines);

	height: calc(#{$lines} * #{$line-height} * 1em);
	line-height: $line-height;
}
