.b-separated_list {
	display: flex;

	@include media(sm) {
		flex-direction: column;
	}

	&-devider {
		margin: 0 rh(2);

		@include media(sm) {
			display: none;
		}

		&::before {
			content: '|';
		}
	}
}
