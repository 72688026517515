@import 'common-flagship_tasting_room';
@import 'common-non_commerce';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}

.b-header.m-transparent:not(.m-inverted):not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
	color: inherit;
}

.b-header-background {
	border-bottom: 1px solid $color-secondary;
}
.b-categories_navigation { 
	 &-item_1:after {
		border-bottom: 3px solid $color-secondary;
 	}
	&-group_1 {
		border-top: 1px solid $color-secondary;
		.t-link_1 , .t-heading_6 {
			color: $color-secondary-text;
		}
	}
}

.b-categories_navigation-list_1 {
	color: $color-primary;
	font-family: $font-alt;
	font-size: 16px;
	letter-spacing: 1px;
	line-height: 24px;
	font-weight: 200 !important;
}

.b-categories_navigation-link_1 {
	line-height: 40px;
}

.b-categories_navigation-link_2 , .b-categories_navigation-link_3.m-view_all {
	color: $color-secondary;
}

.b-product {
	&_tile-name {
		color: $color-secondary-text;
		font-size: 16px;
	}
	&_price-value {
		font-weight: 300;
	}
}

svg {
	stroke: $color-secondary;
}

.b-product_gallery-thumb.m-current:after{
    border-color: $color-secondary !important;
}
.b-product_tile-cta {
	display: none;
}

.b-breadcrumbs-link.m-current {
	color: var(--color-link, $color-primary) !important;
}

.b-breadcrumbs {
	&-link {
		color: var(--color-link, $color-secondary-text) !important;
	}
    &-item {
        align-items: baseline;
        &:not(.m-current) {
            @include media(md-up) {
                @include icon('arrow-right', 8px, 8px, after) {
                    background: $color-breadcrumb-separator_bg;
                    margin: 2px 15px 0;
                }
            }
        }
    }
}

.b-footer-contacts .content-asset {
	@include media(sm) {
		padding-bottom: 0;
	}
}
.b-footer {
	&-customer_service , &-need_help{
		visibility: hidden;
	}
	&-bottom_info, &_legal-links {
		@include media(sm) {
			align-items: center;
		}
	}
	&_legal-links {
		& a {
			color: $color-accent;
		}
	}
	&-copyright {
		color: $color-shade_10 !important;
		opacity: initial;
		@include media(sm) {
			text-align: center;
		}
	}
	&-social {
		@include media(md) {
			grid-area: auto !important;
		}
	}
	&_social {
		@include media(md-up) {
			padding-top: 64px;
		}
		text-align: left;
		&-title {
			color: $color-shade_9 !important;
			font-family: $font-alt;
			text-transform: capitalize;
			@include media(sm) {
				text-align: center;
			}
		}
		&-links {
			justify-content: flex-start;
		   & a {
				padding-right: 16px;
			}
			@include media(sm) {
				justify-content: center;
			}
		}
	}
	@include media(md-down) {
		background: transparent;
	}
	background: transparent;
	border-top: 1px solid $color-shade_5;
	&_navigation {
		&-content {
			@include media(md-down) {
				align-items: center;
				letter-spacing: 0.4px;
			}
		}
		&-title {
			text-transform: capitalize;
			letter-spacing: 0.4px;
			color: $color-shade_9 !important;
			line-height: 24px;
			font-family: $font-alt;
			@include media(md-down) {
				text-align: center;
			}
		}
		&-link {
			color: $color-accent;
		}
	}
}

.logo {
	&-desktop {
		margin-top: 5%;
		@include media(sm){
			display: none;
	
		}
		img {
			width: 100%;
		}
	}
	&-mobile {
		display: none;
		margin-top: 15%;
		margin-left: -5%;
		@include media(sm){
			display: block;
		}
		img {
			width: 100%;
		}
	}
}

.b-header.m-stuck .logo-desktop {
	@include media(sm){
		display: none;
	}
	
	display: block;
}
.b-header.m-stuck .logo-mobile {
	@include media(sm){
		display: block;
	}
}

.f-input_checkbox input , .f-input_radio-field , .f-input_radio-field.m-checked {
	background: $color-secondary-text;
}
.b-refinements_accordion-button:after {
    background: var(--icon-color,$color-secondary-text) !important;
}
.b-refinement_list-more {
	color: $color-secondary-text;
}
.b-refinements_accordion-button {
	font-size: 16px !important;
}

.l-products_results-products_number {
	font-size: 16px !important;
    line-height: 24px !important;
	text-transform: capitalize;
	letter-spacing: 0.4px;
}

.l-products_results {
	&-sorting {
		@include media(md-down){
			grid-template-columns: 1fr 1fr;
		}
	}
}

.l-products_results-refinement_button {
	background: $color-secondary !important;
}

.b-product_badge {

	&.m-promo {
		background: $color-secondary;
	}
	color: $color-primary-background !important;
	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		background-image: url('./svg-icons/badge-shape.svg');
		border-radius: 50%;
	}

	&-abbr, &-text {
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;

		@include media(md-down) {
			font-size: 12px;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}
	&-points {
		color: $color-shade_7;
        font-family: $font-alt;
        font-size: 16px !important;
        letter-spacing: 0.3px;
		margin-right: 4px;
		@include media(sm) {
			font-size: 14px !important;
			margin-right: 0px;
		}
	}
	&-text {
        font-size: 12px !important;
        letter-spacing: 0.2px;
        font-family: $font-alt;
        line-height: 18px;
        color:$color-primary;
		margin-right: 4px;
		@include media(sm) {
			margin-right: 0px;
		}
    }
}

.b-carousel-control {
    border: 0;
}

.b-variations_item-label {
    color: $color-shade_3 !important;
}

// subheading in homepage
.subhead_ {
	font-family: $font-alt;
	font-size: 21px;
	letter-spacing: 0.5px;
	line-height: 40px;
	color: $color-shade_7;
	font-weight: 200;
}

.b-carousel-title {
  font-family: $font-alt;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 48px;
  margin-left:25px;
}

.mainhead {
	font-family: $font-alt;
	font-size: 40px;
	letter-spacing: 1px;
	line-height: 56px;
	text-align: center;
	font-weight: 100;
}

.b-header-links_item:before, .b-header-utility_item:before {
	color: $color-secondary;
}

.b-store_locator-title {
	font-family: $font-alt;
	font-size: 36px;
	letter-spacing: 0.3px;
	line-height: 48px;
}

//PROMO HEADER CONTENT
.b-header_promo {
	background: $color-shade_11;
	&-content {
		padding: 12px 0;
		color: $color-shade_10;
		font-family: $font-main;
		font-size: 16px;
		letter-spacing: 0.5px;
		line-height: 24px;
		& a {
			color: $color-accent;
		}
	}
}

.b-carousel {
    &-control{
        border: 0;
        --icon-color: var(--carousel-control-color, #{$color-carousel_control});
        &[disabled] {
            --icon-color: var(--carousel-control-color, #{$color-carousel_control__disabled});
            opacity: initial;
            }
    }
    &-pagination_control{
        border: 1px solid $color-secondary;
    }
}

.t-paragraph_1 {
	font-style: 16px;
	color: $color-shade_10;
}

// back to top icon
.b-back_to_top {
	@include media(sm) {
		&-icon {
			margin-bottom: 0 !important;
		}
		&-icon_circle {
			color: transparent;
		}
		&-copy {
			font-size: 16px;
			color: $color-shade_10;
			text-transform: capitalize;
			font-weight: 300;
		}
	}
}

//AGE GATE
.b-age_gate{
	&-inner {
		color: $color-primary;
	}
	&-button {
		color: $color-primary-background;

		&:hover {
			background-color: $color-primary;
			color: $color-secondary;
		}
	}
}

.f-input_text-label span{
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 400px;
	@include media(sm){
		max-width: 320px;
	}
}

.b-age_gate-header {
	width: 356px;
}

.b-logo-image {
	display: flex;
	align-items: center;
}

.b-header-navigation_link.m-stores{
	text-transform: uppercase;
}