/*md

# g-separator

Separator

```scss_example
	b-form {
		@include g-separator;

		// ...
	}
}
```
*/

@mixin g-separator(
	$mod: bottom,
	$spacing: rh(6),
	$color: $color-shade_4
) {
	border-#{$mod}: 1px solid $color;
	padding-#{$mod}: $spacing;
}
