.b-shipping_panel {
	@include g-panel($el: overlay);

	&.m-opened {
		@include g-panel($el: overlay, $mod: opened);
	}

	&-inner {
		@include g-panel($el: panel);
		@include g-panel($el: panel, $mod: left);

		background-color: $color-shipping_panel-background;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
	}

	&.m-opened &-inner {
		@include g-panel($el: panel, $mod: opened);
		@include g-panel($el: panel, $mod: left_opened);
	}

	&.m-dialog_active &-inner {
		opacity: 1;
		overflow: visible;
		visibility: visible;
	}

	&-close {
		@include icon(close, $icon-width: 16px, $icon-height: 16px);

		cursor: pointer;
		padding: rh(2);
		position: absolute;
		right: 10px;
		top: 10px;
	}

	&.m-image_background &-top_wrap {
		background: $color-shipping_panel__inverse-background center center / cover;
		color: $color-shipping_panel_top-with_image;
	}

	&-top {
		border-bottom: 1px solid $color-shipping_panel-divider;
		max-height: 75vh;
		padding: rh(8 7 8 6);

		@include media(sm) {
			padding: rh(6 6 6 7);
		}
	}

	&.m-image_background &-top {
		border-bottom: none;
	}

	&.m-image_background &-close {
		color: $color-shipping_panel_top-with_image;
	}

	&-title {
		font-family: $font-alt;
		font-size: 26px;
		letter-spacing: 0.3px;
		line-height: 40px;
		margin-bottom: rh(2);

		@include media(md-down) {
			font-size: 22px;
		}
	}

	&-description {
		font-size: 16px;
		letter-spacing: 0.16px;
		line-height: 22px;
	}
}
