/*md

# b-user_content

This component is used to provide regular document styling in places where content managment team could
insert rich HTML markup.

## Headings

```html_example
<div class="b-user_content">
	<h1>General Information</h1>
	<h2>General Information</h2>
	<h3>General Information</h3>
	<h4>General Information</h4>
	<h5>General Information</h5>
</div>
```

## Paragraphs

```html_example
<div class="b-user_content">
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
</div>
```

## An unordered list

```html_example
<div class="b-user_content">
	<ul>
		<li>Provide, operate, and maintain our webste</li>
		<li>Improve, personalize, and expand our webste</li>
		<li>Understand and analyze how you use our webste</li>
	</ul>
</div>
```

## An ordered list

```html_example
<div class="b-user_content">
	<ol>
		<li>Develop new products, services, features, and functionality</li>
		<li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
		<li>Send you emails</li>
		<li>Find and prevent fraud</li>
	</ol>
</div>
```

## Full page

```html_example
<div class="b-user_content">
	<h1>
		Privacy Policy
	</h1>
	<h2>General Information</h2>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
	<ul>
		<li>Provide, operate, and maintain our webste</li>
		<li>Improve, personalize, and expand our webste</li>
		<li>Understand and analyze how you use our webste</li>
	</ul>
	<ol>
		<li>Develop new products, services, features, and functionality</li>
		<li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
		<li>Send you emails</li>
		<li>Find and prevent fraud</li>
	</ol>
	<p>All orders are subject to product availability. If an item is not in stock at the time you place
		your order, we will notify you and refund you the total amount of your order, using the original
		method of payment.
	</p>
</div>
```
*/

.b-user_content {
	> h1 {
		@include t-heading_1;

		margin-bottom: rh(8);
	}

	> h2 {
		@include t-heading_2;
	}

	> h3 {
		@include t-heading_3;
	}

	> h4 {
		@include t-heading_4;
	}

	> h5 {
		@include t-heading_5;
	}

	> h2,
	> h3,
	> h4,
	> h5 {
		margin-bottom: rh(4);
		margin-top: rh(8);

		&:first-child {
			margin-top: 0;
		}
	}

	p {
		margin-bottom: rh(4);
	}

	ul,
	ol {
		display: block;
		margin-bottom: rh(4);
		padding-left: rh(5);
	}

	li {
		display: list-item;
		margin-bottom: rh(1);
	}

	ul {
		list-style: disc outside;
	}

	ol {
		list-style: decimal outside;
	}
}
