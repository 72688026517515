/* stylelint-disable */
.l-page {
	#wtb-overlay {
		background: $color-store_locator-background;

		#closeBtn {
			@include t-heading_6;
			@include icon(close, $position: after, $icon-width: 14px, $icon-height: 14px) {
				margin-left: rh(2);
			}

			align-items: center;
			background: $color-store_locator-close_background;
			border: 0;
			color: $color-store_locator-close_text;
			display: flex;
			height: 60px;
			justify-content: center;
			text-shadow: none;
		}

		#overlayContent {
			#resultListings {
				background: $color-store_locator-nav_background;
				box-shadow: none;
				width: 495px;

				@include media(md) {
					width: 50%;
				}

				@include media(sm) {
					width: 100%;
				}

				#resultItemsTab,
				#wtbLogo {
					display: none;
				}

				#listingsWrapper {
					#listings {
						border-radius: 0;
						box-shadow: none;
						height: 100%;
						margin: 0;
						padding: rh(0 3 0 6);
						width: 100%;

						@include media(sm) {
							padding: rh(0 3);
						}

						.listingItem {
							align-items: center;
							border-color: $color-store_locator-border;
							display: flex;
							padding: rh(4 0 3 4);

							&:hover {
								background: $color-store-hover;
							}

							&:hover .acctInfo {
								font-weight: bold;
							}

							.acctInfo {
								@include t-paragraph_2;

								width: 100%;
							}

							.marker {
								margin-right: rh(4);
							}
						}
					}
				}
			}
		}

		#mobileNavWrapper {
			background: $color-store_locator-nav_background;
			border-bottom: 1px solid $color-store_locator-border;
			margin: rh(0 3);
			text-align: center;
			width: calc(100% - #{rh(6)});

			#mapNav,
			#acctNav {
				background: $color-store_locator-nav_background;
				border: none;
				color: $color-store_locator-nav_text;
				font-size: 16px;
				font-weight: bold;
				height: 70px;
				letter-spacing: 2.4px;
				line-height: 24px;
				padding: rh(0 3);
				text-transform: uppercase;
				width: auto;

				&[data-acct-nav-clicked="true"] {
					color: $color-store_locator-close_text;
				}
			}

			#mapNav {
				text-align: right;
			}

			#acctNav {
				text-align: left;
			}
		}

		#noData {
			@include t-paragraph_1;

			color: $color-error;
			margin: 0 auto;
			max-width: 680px;
			margin-top: rh(10);

			@include media(sm) {
				padding: 0 var(--grid-gutter);
			}
		}
	}

	.gm-style {
		.gm-style-iw-c {
			@include t-paragraph_2;

			border-radius: 0;
			border: 1px solid $color-store-border;
			padding: rh(4) !important; // to overwrite inline styles

			.infoWinHeading {
				font-weight: 400;
			}
		}

		.gm-style-iw-t::after {
			display: none;
		}

		.gm-style-iw-d {
			max-height: none !important;// to overwrite inline styles
			min-width: 180px;
			overflow: auto !important;// to overwrite inline styles
		}
	}

	.gm-ui-hover-effect {
		// to overwrite inline styles
		height: auto !important;
		right: 10px !important;
		top: 10px !important;
		width: auto !important;

		img {
			margin: 0 !important; // to overwrite inline styles
		}
	}
}
/* stylelint-enable */
