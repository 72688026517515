/*md

# Full Width Container

Full Width Container supports 1 layout cell covering the full width of the viewports

## Configuration options

Full Width Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%, 50%, 30%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

## Examples:

### Full Width Container. Width = grid-width; Top margin = M

```html_example
<div class="b-1_up m-grid_width" style="margin-top: var(--m);">
	<div class="b-1_up-cell">
		<img src="https://placehold.it/1400x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Full Width Container. Width = full-width; Top margin = zero

```html_example
<div class="b-1_up m-full_width" style="margin-top: var(--zero);">
	<div class="b-1_up-cell">
		<img src="https://placehold.it/1400x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Full Width Container. Width = 60%; Top margin = XXL

```html_example
<div class="b-1_up" style="--pd-layout_width: 60%; margin-top: var(--xxl);">
	<div class="b-1_up-cell">
		<img src="https://placehold.it/1400x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

*/

.b-1_up {
	@include g-pd_layout(wrapper);

	&-cell_inner {
		@include g-pd_layout(cell);
	}
}
