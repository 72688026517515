.b-suggestions_product {
	display: flex;
	font-size: 12px;

	&-picture_wrap {
		margin-right: rh(4);
	}

	&-picture {
		display: block;
		max-width: 100%;
		width: 70px;
	}

	&-title {
		font-weight: bold;
		margin-bottom: rh(1);
		text-transform: uppercase;
	}

	&-price {
		font-weight: bold;
	}
}
