    .b-filters_wrap{
        display: flex;
        gap: 1rem;

        &.b-filters_wrap--left, &.b-filters_wrap--right{
            flex-wrap: wrap;
             @include media(md-up) {
                flex-wrap:unset;
             }
             .b-filters_filters{
                width: 100%;
                 @include media(md-up) {
                    width: auto;
                 }
             }
            .experience-filters{
                display: flex;
                 @include media(md-up) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                 }

            }

             .b-filters_cards{
                width: 100%;
                 @include media(md-up) {
                    width: 90%;
                 }
            }
        }
         &.b-filters_wrap--top, &.b-filters_wrap--bottom{
            flex-wrap: wrap;
            .b-filters_filters, .b-filters_cards{
                width: 100%;
            }
            .b-filters_cards{
                width: 100%;
                margin-left: 15px;
            }
         }
         &.b-filters_wrap--bottom{
            flex-direction: column;
              @include media(md-up) {
                 flex-direction: column-reverse;
              }
         }
         &.b-filters_wrap--right{
            flex-direction: row-reverse;
         }
    }
    .b-filters_filters{
        margin-top: var(--marginTop);
    }
    .experience-commerce_assets-filterCard{
        width: 100%;
        margin-bottom: 1rem;
        @include media(md-up) {
            width: calc(100% / var(--cardsRow, 3) - 1rem);
            
        }
    }
    .experience-region.experience-filters{
        display: flex;
        justify-content: center;
        flex-wrap:wrap;
        gap: 1rem;
        padding: 2rem;
    }