/*md

# Banner Type 1

## Configuration options

Banner Type 1 supports the following configuration options:

1. Banner width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Banner Horizontal paddings

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="--pd_banner-padding-horizontal: var(--VALUE);"]

4. Banner Vertical paddings

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="--pd_banner-padding-vertical: var(--VALUE);"]

5. Banner Aspect Ratio

	Could be one of values: auto, 1:1, 3:4, 4:3, 16:9

	How to use in code:
		CSS class [<div class="b-banner_2-inner m-1_1"] / or m-auto, m-3_4, m-4_3, m-16_9

5. Banner Background Color

	How to use in code:
		inline CSS [style="background-color: var(--VALUE);"]

6. Region Width

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="--pd_banner-region_width: NN%;"]

7. Region Horizontal Alignment

	Could be one of values: left, center, right

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="align-self: start/center/end;"></div>]

7. Region Vertical Alignment

	Could be one of values: top, middle, bottom

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="justify-self: start/center/end;"></div>]

## Examples:

### Example 1

Banner with background image.

Banner width = 50%, margin top = zero, horizontal padding = M, vertical padding = M, aspect ratio = 16:9

Region width = 80%, horizontal alignment = center, vertical alignment = middle

Region Content: Textbox

```html_example
<div class="b-banner_2" style="
		justify-self: center;
		margin-top: var(--zero, 0);
		--pd_banner-width: 50%;
		--pd_banner-padding-horizontal: var(--m);
		--pd_banner-padding-vertical: var(--m);
">
	<div class="b-banner_2-inner m-ratio m-16_9" style="
			background-color: lightblue;
	">
		<picture class="b-banner_2-background">
			<source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
			<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
			<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
			<source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
			<img class="b-banner_2-background_image"
				src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
				alt="Alternative text"
				title="Image title"
			/>
		</picture>
		<div style="--pd_banner-region_width:80%;" class="b-banner_2-region m-x_center m-y_middle">
			<div class="b-banner_2-region_inner">
				<div class="b-text_box" style="
						--component-width: 100%;
						margin-top: var(--zero, 0);
						justify-self: center;
						text-align: left;
				">
					<h2 class="t-heading_2">Lorem ipsum dolor sit amet</h2>
					<p class="t-paragraph_3">Duis aute irure dolor in reprehenderit in voluptate <a href="#" class="t-link_1">Link 3</a> velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
				</div>
			</div>
		</div>
	</div>
</div>
```

### Example 2

Banner with background color

Banner width = 50%, margin top = L, horizontal padding = XL, vertical padding = L, aspect ratio = 16:9

Region width = 80%, horizontal alignment = center, vertical alignment = bottom

Region Content: Textbox

```html_example
<div class="b-banner_2" style="
		justify-self: center;
		margin-top: var(--zero, 0);
		--pd_banner-width: 50%;
		--pd_banner-padding-horizontal: var(--m);
		--pd_banner-padding-vertical: var(--m);
">
	<div class="b-banner_2-inner m-ratio m-16_9" style="
			background-color: lightblue;
	">
		<div style="--pd_banner-region_width:80%;" class="b-banner_2-region m-x_center m-y_bottom">
			<div class="b-banner_2-region_inner">
				<div class="b-text_box" style="
						--component-width: 100%;
						margin-top: var(--zero, 0);
						justify-self: center;
						text-align: left;
				">
					<h2 class="t-heading_2">Lorem ipsum dolor sit amet</h2>
					<p class="t-paragraph_3">Duis aute irure dolor in reprehenderit in voluptate <a href="#" class="t-link_1">Link 3</a> velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
				</div>
			</div>
		</div>
	</div>
</div>
```

*/

.b-banner_2 {
	width: var(--pd_banner-width);

	@include media(sm) {
		width: 100%;
	}

	&-inner {
		&.m-1_1 {
			--aspect-height: 1;
			--aspect-width: 1;
		}

		&.m-3_4 {
			--aspect-width: 3;
			--aspect-height: 4;

			@include media(sm) {
				--aspect-width: 1;
				--aspect-height: 1;
			}
		}

		&.m-4_3 {
			--aspect-width: 4;
			--aspect-height: 3;
		}

		&.m-16_9 {
			--aspect-width: 16;
			--aspect-height: 9;

			@include media(sm) {
				--aspect-width: 1;
				--aspect-height: 1;
			}
		}

		&.m-ratio {
			padding-bottom: calc(var(--aspect-height, 3) / var(--aspect-width, 4) * 100%);
			position: relative;
		}

		&.m-auto {
			display: grid;
		}
	}

	&-inner.m-ratio &-background {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&-background_image {
		vertical-align: top;
		width: 100%;
	}

	&-inner.m-ratio &-background_image {
		height: 100%;
		object-fit: cover;
		object-position: var(--pd_banner-focal_x, 50%) var(--pd_banner-focal_y, 50%);
		position: absolute;
		top: 0;
	}

	&-inner.m-auto &-background {
		grid-area: 1 / 1 / -1 / -1;
	}

	&-region {
		color: $color-banner_2_region;

		@include media(md-up) {
			width: var(--pd_banner-region_width);
		}
	}

	&-inner.m-auto &-region {
		box-sizing: initial;
		grid-area: 1 / 1 / -1/ -1;
		padding: var(--pd_banner-padding-vertical) var(--pd_banner-padding-horizontal);

		&.m-x_left {
			justify-self: start;
		}

		&.m-x_center {
			justify-self: center;
		}

		&.m-x_right {
			justify-self: end;
		}

		&.m-y_top {
			align-self: start;
		}

		&.m-y_middle {
			align-self: center;
		}

		&.m-y_bottom {
			align-self: end;
		}
	}

	&-inner.m-ratio &-region {
		position: absolute;
		z-index: 1;

		@include media(sm) {
			left: var(--pd_banner-padding-horizontal);
			right: var(--pd_banner-padding-horizontal);
		}

		&.m-x_left {
			left: var(--pd_banner-padding-horizontal);
		}

		&.m-x_center {
			@include media(md-up) {
				left: 50%;
				transform: translateX(-50%);
			}
		}

		&.m-x_right {
			right: var(--pd_banner-padding-horizontal);
		}

		&.m-y_top {
			top: var(--pd_banner-padding-vertical);
		}

		&.m-y_middle {
			top: 50%;
			transform: translateY(-50%);
		}

		&.m-y_middle.m-x_center {
			@include media(md-up) {
				transform: translateX(-50%) translateY(-50%);
			}
		}

		&.m-y_bottom {
			bottom: var(--pd_banner-padding-vertical);
		}
	}

	&-region_inner {
		display: grid;
	}

	&.has-mobile {
		.b-banner_2-backgroundmobile {
			display: none;

			@include media(sm) {
				display: block;
			}
		}

		.b-banner_2-background {
			@include media(sm) {
				display: none;
			}
		}
	}

}
