/*md

# Global alerts

These alerts used to notify blind user about dynamic changes on the page. Ex: load more products,
filters applied, sorting applied etc. To meet basic a11y requirements.

It is recommended not to hide these alerts and make them visible.

<style>
.b-global_alerts {
	position: static !important;
}
</style>

```html_example
<div class="b-global_alerts">
    <div
        class="b-global_alerts-item"
        role="alert"
    >
        Filters applied
    </div>
</div>
```

## Visually hide alerts

It is possible to hide these alerts visually, but it is not recommended.

We have a special modification class for this - `m-visually_hidden`.

*/

.b-global_alerts {
	bottom: rh(5);
	left: rh(5);
	pointer-events: none;
	position: fixed;
	right: rh(5);
	text-align: center;
	z-index: z(notifier);
	@include media(sm){
		bottom: rh(30);
	}

	&.m-visually_hidden {
		@include hide(visually);
	}

	&-item {
		animation: slide-from-bottom $motion-ease;
		background-color: $color-notification-background;
		border-radius: 3px;
		box-shadow: 0 4px 4px rgba(#000, 0.1);
		color: $color-notification-text;
		display: inline-block;
		font-size: 14px;
		margin: 0 auto;
		max-width: 400px;
		min-width: 194px;
		padding: rh(3 4);
		transform: translateY(0); //fix for VoiceOver reader

		@include media(sm) {
			padding: rh(2 3);
		}

		&.m-error {
			background-color: $color-error-background;
			color: $color-error;
		}
	}
}
