/*md

# g-promo*

Mixin for promotion.

```scss_example
.b-cart_promo {
	@include g-promo();

}
```

*/

@mixin g-promo {
	background: $color-product_tile-promo-background;
	border-radius: $configuration-promo-border_radius;
	color: $color-product_tile-promo;
	font-size: 14px;
	letter-spacing: 0.2px;
	line-height: 22px;
	padding: rh(1 3);
	text-align: center;

	@content;
}
