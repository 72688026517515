/*md

# Image Tile Component

## Configuration options

Image Tile Component supports the following configuration options:

1. Component width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%.

	How to use in code:
		inline CSS [style="--component-width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Aspect Ratio Crop

	Could be one of values: 1:1, 3:4, 16:9, 4:3.

	How to use in code:
		CSS class "m-{aspect_ratio}"
		ex: "m-1_1", "m-16_9", "m-3_4" etc.

## Examples:

### Example 1

Component width = 30%, top margin = zero

Aspect ratio = 1:1

```html_example
<div class="b-pd_picture"
	style="
		margin-top: var(--zero);
		--pd-component_width: 30%;"
>
	<picture class="b-pd_picture-inner m-ratio-1_1">
		<source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
		<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
		<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
		<source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
		<img class="b-pd_picture-image"
            src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
</div>
```

### Example 2

Component width = 50%, top margin = l

Aspect ratio = 4:3

```html_example
<div class="b-pd_picture"
	style="
		margin-top: var(--l);
		--pd-component_width: 50%;"
>
	<picture class="b-pd_picture-inner m-ratio-4_3">
	<source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
	<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
	<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
	<source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
		<img class="b-pd_picture-image"
            src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
</div>
```

### Example 1

Component width = 80%, top margin = s, horizontal positioning = left.

Aspect ratio = 16:9

```html_example
<div class="b-pd_picture"
	style="
		justify-self: start;
		margin-top: var(--s);
		--pd-component_width: 80%;"
>
	<picture class="b-pd_picture-inner m-ratio-16_9">
	<source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
	<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
	<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
	<source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
		<img class="b-pd_picture-image"
            src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
</div>
```
*/

.b-pd_picture {
	display: block;
	width: var(--pd-component_width, 100%);

	@include media(sm) {
		width: 100%;
	}

	&-inner {
		display: block;
		padding-bottom: calc(var(--aspect-height, 3) / var(--aspect-width, 4) * 100%);
		position: relative;
		width: 100%;

		&.m-ratio-1_1 {
			--aspect-width: 1;
			--aspect-height: 1;
		}

		&.m-ratio-3_4 {
			--aspect-width: 3;
			--aspect-height: 4;

			// @include media(sm) {
			// 	--aspect-width: 1;
			// 	--aspect-height: 1;
			// }
		}

		&.m-ratio-4_3 {
			--aspect-width: 4;
			--aspect-height: 3;
		}

		&.m-ratio-1_2 {
			--aspect-width: 1;
			--aspect-height: 2;
		}

		&.m-ratio-1_3 {
			--aspect-width: 1;
			--aspect-height: 3;
		}

		&.m-ratio-2_5 {
			--aspect-width: 2;
			--aspect-height: 5;
		}

		
		&.m-ratio-16_9 {
			--aspect-width: 16;
			--aspect-height: 9;

			// @include media(sm) {
			// 	--aspect-width: 1;
			// 	--aspect-height: 1;
			// }
		}

		@include media(sm) {
			&.sm-ratio-1_1 {
				--aspect-width: 1;
				--aspect-height: 1;
			}

			&.sm-ratio-3_4 {
				--aspect-width: 3;
				--aspect-height: 4;
			}

			&.sm-ratio-4_3 {
				--aspect-width: 4;
				--aspect-height: 3;
			}

			&.sm-ratio-1_2 {
				--aspect-width: 1;
				--aspect-height: 2;
			}

			&.sm-ratio-1_3 {
				--aspect-width: 1;
				--aspect-height: 3;
			}

			&.sm-ratio-2_5 {
				--aspect-width: 2;
				--aspect-height: 5;
			}


			&.sm-ratio-16_9 {
				--aspect-width: 16;
				--aspect-height: 9;
			}
		}
	}

	&-image {
		height: 100%;
		left: 0;
		object-fit: cover;
		object-position: var(--pd-picture-focal_x, 50%) var(--pd-picture-focal_y, 50%);
		position: absolute;
		width: 100%;
	}
	&.has-mobile{
		.b-pd_picture-inner{
			@include media(sm) {
				display: none;
			}
			&.mobileImage{
				display: none;
				@include media(sm) {
					display: block;
				}
			}
		}
	}
}
