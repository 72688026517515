/*md

# 5 Column Container

5 Column Container supports:

	* 5 layout cells 25% width each, on M/L/XL viewports
	* 2 columns stacked on S viewports

## Configuration options

5 Column Container supports the following configuration options:

1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-5_up-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = full-width; Top margin = XXL;

Cell 1 - top; Cell 2 - center; Cell 3 - center; Cell 4 - top

```html_example
<div class="b-5_up m-full_width" style="margin-top: var(--xxl);">
	<div class="b-5_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
</div>
```

### Example 2

Width = 80%; Top margin = M;

Cell 1 - top; Cell 2 - bottom; Cell 3 - bottom; Cell 4 - top

```html_example
<div class="b-5_up m-full_width" style="margin-top: var(--m); --pd-layout_width: 80%;">
	<div class="b-5_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: end;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: end;">
		<img src="https://placehold.it/900x400?Layout+content" alt="Image" />
	</div>
	<div class="b-5_up-cell" style="align-self: start;">
		<img src="https://placehold.it/900x600?Layout+content" alt="Image" />
	</div>
</div>
```

*/

.b-5_up {
	@include g-pd_layout(wrapper);

	grid-template-columns: repeat(5, 1fr);

	@include media(sm) {
		grid-template-columns: 1fr;
		width: min-content;
	}

	&-cell_inner {
		@include g-pd_layout(cell);
	}
}