.b-cart_dialog {
	&-msg {
		@include t-paragraph_1;

		margin-bottom: rh(4);
	}

	&-product {
		@include t-heading_6;
	}
}
