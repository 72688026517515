/*md

# b-product_quantity_static*

Some example of product quantity structure.

```html_example
	<div lass="b-product_quantity_static">
		Quantity: <span class="b-product_quantity_static-value">1</span>
	</div>
```

*/

.b-product_quantity_static {
	font-size: 18px;

	&-value {
		font-weight: bold;
	}
}
