.b-sitemap {
	--columns: 4;

	display: grid;

	@include media(md) {
		--columns: 3;
	}

	@include media(sm) {
		--columns: 2;
	}

	&-section {
		align-items: start;
		display: grid;
		gap: rh(6);
		grid-auto-flow: row dense;
		grid-template-columns: repeat(var(--columns), 1fr);
		margin-bottom: rh(5);
	}

	&-section_title {
		@include t-heading_2;

		grid-column: 1 / -1;
	}

	&-subsection {
		margin-bottom: rh(4);

		&.m-wide {
			grid-column: 1 / -1;
		}
	}

	&-subsection.m-wide &-subsection_list {
		display: grid;
		gap: rh(0 6);
		grid-column: 1 / -1;
		grid-template-columns: repeat(var(--columns), 1fr);
	}

	&-subsection_title {
		@include t-heading_6;

		grid-column: 1 / -1;
		margin-bottom: rh(4);
	}

	&-subsection_item {
		margin-bottom: rh(2);
	}

	&-subsection_link {
		@include t-link_1;
	}
}
