$pd_button_sizes: (
	button-l: (
		lg-up: 370px,
		md: 320px,
		sm: 100%
	),
	button-m: (
		lg-up: 300px,
		md: 270px,
		sm: 100%
	),
	button-s: (
		lg-up: 240px,
		md: 220px,
		sm: 100%
	),
);

body {
	@include responsive-var-generator($pd_button_sizes);
}
