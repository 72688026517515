@mixin g-panel($el: panel, $mod: default) {
	@if $el == panel {
		@if $mod == default {
			bottom: 0;
			opacity: 0;
			position: fixed;
			top: 0;
			visibility: hidden;
			width: 460px;
			z-index: 2;

			@include media(md) {
				width: 50%;
			}

			@include media(sm) {
				width: 100%;
			}
		}

		@if $mod == left {
			left: -100%;
			transition: visibility 0.5s, opacity 0.5s, left 0.5s;
		}

		@if $mod == right {
			right: -100%;
			transition: visibility 0.5s, opacity 0.5s, right 0.5s;
		}

		@if $mod == center{
			left: 0;
			right: 0;
			margin: auto;
			transition: visibility 0.5s, opacity 0.5s, right 0.5s;
		}

		@if $mod == opened {
			opacity: 1;
			visibility: visible;
		}

		@if $mod == left_opened {
			left: 0;
		}

		@if $mod == right_opened {
			right: 0;
		}
	}

	@if $el == overlay {
		@if $mod == default {
			&::after {
				background-color: rgba($color-overlay-background, 0.5);
				bottom: 0;
				content: '';
				left: 0;
				opacity: 0;
				position: fixed;
				right: 0;
				top: 0;
				transition: visibility 0.4s, opacity 0.4s, left 0.4s;
				visibility: hidden;
				z-index: 1;
			}
		}

		@if $mod == opened {
			&::after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
