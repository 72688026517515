/*md

# g-message*

Mixin for message tile.

```scss_example
.b-message {
	@include g-message;
}
```

*/

@mixin g-message($_mod: default) {
	@if $_mod == default {
		align-items: center;
		background: $color-message-background;
		border: 2px solid $color-message-border;
		border-radius: 3px;
		color: $color-message-text;
		display: flex;
		font-size: 16px;
		font-weight: 400;
		justify-content: center;
		line-height: 24px;
		padding: rh(3 7);
		text-align: center;

		@include media(sm) {
			padding: rh(4);
		}
	}

	@if $_mod == inversed {
		background: $color-message-background__inversed;
		color: $color-message-text__inversed;
	}

	@if $_mod == error {
		background: $color-error-background;
		border: none;
		color: $color-error;
	}

	@if $_mod == promo {
		background: rgba($color-header_promo-background, 0.2);
		border-color: $color-header_promo-background;
		font-weight: 600;
		letter-spacing: 2.5px;
		text-transform: uppercase;
	}
}
