/*md

# b-product_quantity*

Some example of product quantity structure.

```html_example
	<div lass="b-product_quantity">
		<button class="b-product_quantity-label m-minus">-</button>
		<input class="b-product_quantity-input" type="number" value="1">
		<button class="b-product_quantity-label m-plus">+</button>
	</div>
```

*/

.b-product_quantity {
	align-items: stretch;
	border: $configuration-product_quantity-border_width solid $color-quantity;
	display: flex;
	height: 60px;

	&.m-disabled {
		border-color: rgba($color-quantity, $configuration-product_quantity-disabled-opacity);
	}

	&-input {
		-moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
		background: $color-quantity-background;
		border: none;
		flex-grow: 1;
		font-size: 18px;
		font-weight: bold;
		line-height: 21px;
		min-width: 0;
		text-align: center;

		&:disabled {
			background: $color-quantity-background;
			opacity: $configuration-product_quantity-disabled-opacity;
		}
	}

	&-label {
		cursor: pointer;
		flex-basis: 33%;
		flex-shrink: 0;
		font-size: 20px;
		padding: 0 5px;

		&.m-minus {
			@include icon(minus, $icon-width: 12px, $icon-height: 12px) {
				margin: 0 auto;
			}
		}

		&.m-plus {
			@include icon(plus, $icon-width: 12px, $icon-height: 12px) {
				margin: 0 auto;
			}
		}

		&:disabled.m-minus,
		&:disabled.m-plus {
			opacity: $configuration-product_quantity-disabled-opacity;
		}
	}
}
