/*md

# Textbox Component

## Configuration options

Textbox Component supports the following configuration options:

1. Component width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="--component-width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Component text alignment

	Could be one of values: left, center, right

	How to use in code:
		inline CSS [style="text-align: left/center/right;"]

4. Content

	Textbox Component support HTML tags to format text.

	Any of typography elements can be used as Textbox Component content.

	You can see typography elements in 05-blocks/common/t-typography section.

## Examples:

### Example 1

Width = 100%, Margin top = zero, text align = center

```html_example
<div class="b-text_box"
	style="
        --component-width: 100%;
        margin-top: var(--zero);
        text-align: center;
">
	<h2 class="t-heading_2">Lorem ipsum dolor sit amet</h2>
	<p class="t-paragraph_2">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
	<p class="t-paragraph_3">Duis aute irure dolor in reprehenderit in voluptate <a href="#" class="t-link_1">Link 3</a> velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
</div>
```

### Example 2

Width = 80%, Margin top = XL, text align = right

```html_example
<div class="b-text_box"
	style="
        --component-width: 80%;
        margin-top: var(--xl);
        text-align: right;
">
	<h2 class="t-heading_4">Lorem ipsum dolor sit amet</h2>
	<p class="t-paragraph_1">Consectetur adipiscing elit, <a href="#" class="t-link_2">Link 2</a> sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
	<p class="t-paragraph_2">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
</div>
```

### Example 3

Width = 50%, Margin top = S, text align = left

```html_example
<div class="b-text_box"
	style="
        --component-width: 50%;
        margin-top: var(--s);
        text-align: left;
">
	<ul class="t-unordered_list">
		<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</li>
		<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</li>
		<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</li>
		<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident</li>
	</ul>
</div>
```

*/

.b-text_box {

	h1 {
		@include t-heading_1;
	}
	
	h2 {
		@include t-heading_2;
	}
	
	h3 {
		@include t-heading_3;
	}
	
	h4 {
		@include t-heading_4;
	}

	p {
		@include t-paragraph_1;
	}

	color: var(--mobile-color);
	padding: var(--mobile-padding-vertical) 0;
	margin-top: var(--mobileMarginTop);
	@include media(md-up) {
		padding:0;
		color: var(--color);
		width: var(--component-width, 100%);
		margin-top: var(--marginTop);
		
	}
	&.mobile_font--override{
		@include media(md-down) {
			*{
				font-size: var(--mobile-size);
			}
		}
	}
}
