/*md

# f-input_reset

Default reset input element

## Default

```scss_example
	.b-input {
		@include f-input_reset;
	}
```
*/

@mixin f-input_reset {
	appearance: none;
	background: transparent;
	border: none;
	border-radius: 0;
	min-width: 0; // fix for FF which has a min-width for inputs
	vertical-align: baseline;
	width: 100%;

	&::placeholder {
		color: transparent;
		text-transform: capitalize;
	}

	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 30px $color-primary-background inset; //stylelint-disable-line
		-webkit-text-fill-color: inherit;
		transition-delay: 9999s;
		transition-property: all;
	}
}
