/*md

# Link type 4

Link with an icon.
It inherits and extends `t-link` component

```scss_example
.b-some_link {
	@include t-link_4;
}
```

Mixin accepts following parameters:
- `$icon` (icon name)
- `$icon-height` (1em by default)
- `$icon-width` (1em by default)

*/

@mixin t-link_4(
	$icon: false,
	$icon-width: 1em,
	$icon-height: 1em
) {
	@include t-link(hover);
	@include t-link(visited);

	@include t-link(default, $font-weight: bold) {
		align-items: center;
		display: inline-flex;
		letter-spacing: 2.1px;
		text-decoration: none;
		text-transform: uppercase;
	}

	@if $icon {
		@include icon($icon, $icon-width, $icon-height, before) {
			display: inline-block;
			margin-right: rh(1);
		}
	}

	svg {
		display: inline-block;
		height: var(--link-icon_height, 8px);
		margin-right: rh(1);
		width: var(--link-icon_width, 8px);
	}
}
