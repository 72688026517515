.b-footer_navigation {
	&-title {
		@include t-heading_6;

		margin-bottom: rh(3);

		@include media(sm) {
			margin-bottom: rh(4);
		}
	}

	&-content {
		align-items: start;
		display: flex;
		flex-flow: column;
		font-size: 16px;
		line-height: 24px;

		@include media(sm) {
			align-items: center;
		}
	}

	&-link {
		@include t-link_5(
			$color: var(--color-footer-text, $color-footer-text),
			$color-visited: $color-footer-text
		);

		font-size: 16px;
		line-height: 24px;
		margin-bottom: rh(3);

		@include media(sm) {
			margin-bottom: rh(4);
		}

		&.sitemap-text {
			@include media(md) {
				font-size: 12px;
			}
		}
	}
}
