.b-product_tile {
	$_controls-height: 52px;

	display: grid;
	font-family: $font-main;
	grid-template:
		'image'
		'badge'
		'brand'
		'name'
		'price'
		'description'
		'promo'
		'restriction'
		'availability'
		'actions' 1fr;
	text-align: center;

	.b-carousel & {
		padding-bottom: 1px;
	}

	&-image_wrapper {
		grid-area: image;
		margin-bottom: rh(4);
		position: relative;

		@include media(sm) {
			margin-bottom: rh(3);
		}
	}

	&-image {
		@include g-product_image;

		&.m-not_available {
			opacity: 0.5;
		}

		img {
			@include g-product_image(img);
		}
	}

	&-brand {
		font-size: $configuration-product_tile-brand-font_size;
		font-weight: $configuration-product_tile-brand-font_weight;
		grid-area: brand;
		height: calc(#{$configuration-product_tile-brand-line_height} * 1em);
		letter-spacing: $configuration-product_tile-brand-letter_spacing;
		line-height: $configuration-product_tile-brand-line_height;
		margin-bottom: rh(3);
		overflow: hidden;
		padding: 0 rh(2);
		text-overflow: ellipsis;
		text-transform: uppercase;
		white-space: nowrap;

		@include media(sm) {
			font-size: $configuration-product_tile-brand-font_size__small;
			margin-bottom: rh(2);
		}
	}

	&-name {
		cursor: pointer;
		font: $configuration-product_tile-name-font;
		grid-area: name;
		letter-spacing: $configuration-product_tile-name-letter_spacing;
		margin-bottom: rh(3);
		overflow: hidden;
		text-transform: $configuration-product_tile-name-transform;

		@include media(sm) {
			--product_tile-name-line_height: 1.4;

			font-size: $configuration-product_tile-name-font_size__small;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&-link {
		@include lines-clamp-reserve(2, var(--product_tile-name-line_height));

		--product_tile-name-line_height: 1.3;

		@include media(sm) {
			--product_tile-name-line_height: 1.4;
		}
	}

	&-price {
		grid-area: price;
		margin-bottom: rh(3);
	}

	.b-product_price {
		font-weight: 600;
		justify-content: center;
	}

	&-description {
		@include lines-clamp(var(--product_tile-description-lines));

		--product_tile-description-lines: 1;
		--product_tile-description-line-height: 1.4;

		color: $color-product_tile-description;
		font-size: 14px;
		font-weight: $configuration-product_tile-description-font_weight;
		grid-area: description;
		letter-spacing: 0.2px;
		line-height: var(--product_tile-description-line-height);
		margin-bottom: rh(3);
		overflow: hidden;

		@include media(sm) {
			--product_tile-description-lines: 2;
			--product_tile-description-line-height: 1.2;

			font-size: 13px;
		}
	}

	&-promotion {
		@include g-promo;
		@include lines-clamp(2);

		grid-area: promo;
		margin-bottom: rh(5);

		@include media(sm) {
			font-size: 13px;
			margin-bottom: rh(4);
		}
	}

	&-restriction {
		color: $color-error;
		font-size: 16px;
		grid-area: restriction;
		letter-spacing: 0.2px;
		line-height: 24px;
		margin-bottom: rh(5);

		@include media(sm) {
			font-size: 13px;
			font-weight: 600;
			letter-spacing: 0.5px;
			line-height: 18px;
			margin-bottom: rh(3);
		}
	}

	&-availability {
		align-self: end;
		grid-area: availability;
		margin-bottom: rh(3);

		.b-product_availability {
			font-size: 14px;
		}
	}

	&-actions {
		align-self: end;
		display: flex;
		grid-area: actions;
		min-width: 0;

		@include media(sm) {
			display: block;
		}

		&.m-restriction {
			min-height: 52px;

			@include media(sm) {
				align-items: flex-end;
				display: flex;
				min-height: 104px;
			}
		}

		.b-carousel & {
			@include media(sm) {
				display: flex;
			}
		}
	}

	&-quantity {
		width: 40%;

		@include media(md) {
			width: 50%;
		}

		@include media(sm) {
			width: 100%;
		}
	}

	.b-product_quantity {
		height: $_controls-height;
	}

	&-cta {
		@include g-button($configuration-product_tile-cta_type, $configuration-product_tile-cta_modification);

		height: $_controls-height;
		padding-left: 0;
		padding-right: 0;
		width: 60%;

		@include media(md) {
			width: 50%;
		}

		@include media(sm) {
			width: 100%;
		}

		&.m-short {
			@include media(sm) {
				@include icon(bag, $icon-width: 13px, $icon-height: 16px) {
					flex-shrink: 0;
					margin-right: rh(2);
				}
			}

			.b-carousel &::before {
				@include media(sm) {
					display: none;
				}
			}
		}

		&.m-short:disabled::before {
			display: none;
		}

		&.m-wide {
			width: 100%;
			&.h-disabled {
				pointer-events: none;
				opacity: 1;
				background-color: $color-primary-background;
				color: $color-shade_4;
				border-color: $color-shade_4;
				border-radius: 0;
			}
		}

	}

	// START LIST VIEW MODIFICATION
	&.m-list {
		align-items: start;
		border-bottom: 1px solid $color-filters-border;
		display: grid;
		gap: rh(0 13);
		grid-template:
			'image badge'
			'image brand'
			'image name'
			'image price'
			'image description'
			'image promo'
			'image details'
			'image actions' 1fr
			/
			330px auto;
		max-width: $size-product_tile-wide-max_width;
		text-align: left;

		@include media(sm) {
			gap: rh(0 9);
			grid-template-columns: 165px 1fr;
		}
	}

	&.m-list &-image_wrapper {
		margin-bottom: 0;
	}

	&.m-list &-price {
		justify-content: flex-start;
		margin-bottom: rh(4);

		@include media(sm) {
			margin-bottom: rh(3);
		}
	}

	&.m-list &-description,
	&.m-list &-details {
		margin-bottom: rh(4);

		@include media(sm) {
			margin-bottom: rh(3);
		}
	}

	&.m-list &-promotion {
		margin-bottom: rh(4);

		@include media(sm) {
			line-height: 16px;
			margin-bottom: 0;
		}
	}

	&.m-list &-actions {
		text-align: center;

		@include media(sm) {
			display: none;
		}
	}

	&.m-list &-details {
		@include t-paragraph_3;

		grid-area: details;
	}

	&.m-list &-cta {
		width: 60%;

		@include media(sm) {
			width: 100%;
		}
	}
	// EO LIST VIEW MODIFICATION
	// START BADGES

	.b-product_badge.m-promo {
		left: rh(4);
		position: absolute;
		top: rh(4);

		@include media(sm) {
			left: rh(2);
			top: rh(2);
		}
	}

	.b-product_badge.m-rating_wide {
		grid-area: badge;
		margin-bottom: rh(3);

		@include media(sm) {
			margin-bottom: rh(2);
		}
	}

	&.m-list .b-product_badge.m-rating_wide {
		margin: 0;
	}
	// EO BADGES
	.b-add_to_wishlist {
		padding: rh(5);
		position: absolute;
		right: 0;
		top: 0;

		@include media(sm) {
			padding: rh(3);
		}
	}

	// START grid tile modification
	&.m-grid .b-product_quantity {
		@include media(md-up) {
			border-right: 0;
		}
	}

	&.m-grid &-details {
		display: none;
	}
	// EO grid tile modifications
	&.m-page_designer {
		width: 100%;
	}

	&.m-static {
		max-width: var(--pd_product-maxwidth-xl, 100%);

		@include media(lg) {
			max-width: var(--pd_product-maxwidth-l, 100%);
		}

		@include media(md) {
			max-width: var(--pd_product-maxwidth-m, 100%);
		}

		@include media(sm) {
			max-width: var(--pd_product-maxwidth-s, 100%);
		}
	}
}
