/*
# Input - type date

Default input element

```scss_example
	.b-some_context_component {
		@include f-input_date($style: default);
	}
```
*/
@mixin _date-picker-icon {
	height: 20px;
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	width: 22px;
}

@mixin f-input_date($_mod: default) {
	@if $_mod == default {
		.f-input_date {
			position: relative;

			@include icon(calendar, $position: after) {
				@include _date-picker-icon;

				pointer-events: none;
			}

			&-field {
				@include f-input_default(default);

				border: 1px solid $color-form-input-border;
				color: $color-form-label;
				padding: 21px 40px 7px 15px;

				@include media(sm) {
					padding: 28px 40px 10px 15px;
				}

				&::-webkit-calendar-picker-indicator {
					@include _date-picker-icon;

					opacity: 0;
				}

				&:hover {
					@include f-input_default(focused);
				}

				&.m-invalid {
					@include f-input_default(invalid);
				}

				&:disabled {
					@include f-input_default(disabled);
				}

				&:focus,
				&:valid {
					color: $color-form-input;
				}

				&::placeholder {
					color: $color-form-label;
					text-transform: uppercase;
				}
			}

			&-label {
				@include f-label(default);

				left: 15px;
				pointer-events: none;
				position: absolute;
				top: 14px;
				transform: translateY(-40%) scale(0.75);

				@include media(sm) {
					top: 19px;
				}
			}

			&.m-required &-label {
				@include f-label(required);
			}

			&-field:disabled ~ &-label {
				color: $color-form-input__disabled;
			}
		}
	}
}
