@charset "UTF-8";
@import 'main';
@import '03-base/print';
@import '03-base/general';
@import '03-base/general_harmony';
@import '03-base/helpers/all';
@import '03-base/normalize-forms';
@import '03-base/normalize-base';
@import '03-base/pd-margins';
@import '03-base/pd-buttons';
@import '05-blocks/global-animations';
@import '05-blocks/common/b-global_alerts';
@import '05-blocks/buttons/all';
@import '05-blocks/common/b-back_to_top';
@import '05-blocks/common/b-button_remove';
@import '05-blocks/common/b-button_close';
@import '05-blocks/common/b-countdown';
@import '05-blocks/common/b-loader';
@import '05-blocks/common/b-overlay';
@import '05-blocks/common/b-search_input';
@import '05-blocks/common/b-separator';
@import '05-blocks/common/b-tabs';
@import '05-blocks/common/forms/all';
@import '05-blocks/common/g-accordion';
@import '05-blocks/common/t-typography';
@import '05-blocks/header/b-account_menu';
@import '05-blocks/header/b-login_panel';
@import '05-blocks/header/b-minicart_popup';
@import '05-blocks/header/b-search_dialog';
@import '05-blocks/header/b-shipping_panel';
@import '05-blocks/header/b-shipping_states';
@import '05-blocks/header/b-suggestions_category';
@import '05-blocks/header/b-suggestions_product';
@import '05-blocks/header/b-header_message';
@import '05-blocks/header/b-suggestions';
@import '05-blocks/footer/b-footer';
@import '05-blocks/footer/l-sitemap';
@import '05-blocks/footer/b-footer_navigation';
@import '05-blocks/footer/b-footer_info';
@import '05-blocks/footer/b-footer_social';
@import '05-blocks/footer/b-newsletters';
@import '05-blocks/footer/b-footer_legal';
@import '05-blocks/common/b-age_gate';
@import '05-blocks/common/b-highlighter';
@import '05-blocks/common/b-continue_shopping';
@import '05-blocks/common/b-link_phone';
@import '05-blocks/common/b-dialog';
@import '05-blocks/common/b-user_content';
@import '05-blocks/common/b-expandable';
@import '05-blocks/common/b-message';
@import '05-blocks/common/b-separated_list';
@import '05-blocks/common/b-reserve_bar_cart';
@import '05-blocks/common/b-customer_service_info';
@import '05-blocks/cart/b-minicart';
@import '05-blocks/cart/b-minicart_product';
@import '05-blocks/cart/b-cart_product_details';
@import '05-blocks/cart/b-cart_dialog';
// @import '05-blocks/pdp/b-availability'; // check
@import '05-blocks/product/all';
@import '05-blocks/cart/b-payment_total';
@import '05-blocks/static-pages/b-sitemap';
@import '05-blocks/static-pages/b-contact_us';
@import '05-blocks/page_designer/all';
@import '05-blocks/store-locator/b-store_locator';
@import '05-blocks/store-locator/b-store_locator_details';

// common-***.css and page-specific CSS files
// are loaded asynchronously, so we should make
// sure page will be visible only after
// common-***.css is loaded.
body {
	--page_opacity: 1;
	--page_visibility: visible;
}

.b-header-account,
.b-header-navigation_bottom,
.b-header-navigation_link {
	display: block;
}

.video-container {
	overflow: hidden;
	padding-top: 52%;
	position: relative;
	width: 100%;

	iframe {
		border: none;
		bottom: 0;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}
}

.b-pd_picture-image {
	&-fit-contain {
		object-fit: contain;
	}
}

.age-gate-enter-form {
	display: flex;
	justify-content: center;

	@include media(sm) {
		flex-wrap: wrap;
	}

	.age-gate-form-field {
		width: 120px;

		@include media(sm) {
			width: 25%;
		}
	}

	.age-gate-input {
		color: $color-age-gate-input;
		font-size: 16px;
		font-weight: bold;
		height: 50px;
		padding-left: 10px;

		@include media(sm) {
			margin-bottom: 8px;
		}

		&:not(:last-child) {
			margin-right: 10px;
			width: 90%;
		}
	}

	input[type=number]::-webkit-outer-spin-button,
	input[type=number]::-webkit-inner-spin-button {
		display: unset;
	}

	.b-form-message.m-error {
		margin: 8px 8px 32px 0;
		padding: 8px;
		width: 90%;

		@include media(sm) {
			font-size: 12px;
			line-height: 20px;
			padding: 4px;
		}
	}

	.cta_btn--age-gate {
		background: $color-background-age-gate-cta;
		border: 1px solid $color-border-age-gate-cta;
		color: $color-age-gate-input;
		display: inline-block;
		font-size: 0.88889em;
		font-style: normal;
		font-weight: 600;
		letter-spacing: 0.01em;
		min-width: 154px;
		padding: 11px 25px;
		position: relative;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		transition: all 0.3s linear;
		z-index: 1;

		@include media(sm) {
			margin-top: 8px;
			width: 100%;
		}
	}
}

.price-override-tool {
	display: none;
}

.b-product_badge {
	&.m-promo {
		width: fit-content;
	}

	&-image {
		border: none;

		img {
			height: 84px;
			width: 84px;

			@include media(sm) {
				height: 50px;
				width: 50px;
			}
		}
	}

	&.m-rating_points {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 84px;
		justify-content: center;
		margin-bottom: 16px;
		right: 0 !important;
		width: 84px;

		@include media(sm) {
			height: 50px;
			width: 50px;
		}
	}
}

.badges {
	bottom: 30px;
	display: grid;
	grid-gap: 10px;
	grid-template-areas:
		'myArea1'
		'myArea2'
		'myArea3';
	position: absolute;
	right: 1px;
	z-index: 1;

	.myArea1 {
		grid-area: myArea1;
		margin-bottom: 0;
	}

	.myArea2 {
		grid-area: myArea2;
		margin-bottom: 0;
	}

	.myArea3 {
		grid-area: myArea3;
		margin-bottom: 16px;
	}
}

.b-product_price {
	&-generic {
		display: none;
	}
}

#b-product_price {
	&-allbundleProductsPrice {
		@include media(sm) {
			display: none;
		}
	}

	&-bundlePrice {
		@include media (sm) {
			display: block;
		}

		@include media (md-up) {
			display: none;
		}
	}
}

.b-footer {
	&_social {
		@include media(md-up) {
			padding-top: 64px;
		}
	}
}

.b-footer-email_subscription {
	+.b-footer-social {
		.b-footer_social {
			padding-top: 0;
		}
	}
}

.strike-through-oldprice {
	text-decoration: line-through;
}

.b-product_quantity {
	&.quantity-color {
		background: $color-quantity-picker;
		border: 0 !important;
	}

	.b-product_quantity-input {
		&.quantity-color {
			background: $color-quantity-picker;
		}
	}
}

.b-product_tile {
	&.m-grid {
		.b-product_quantity {
			&.quantity-color {
				background: $color-quantity-picker;
				border: 0 !important;
			}

			.b-product_quantity-input {
				&.quantity-color {
					background: $color-quantity-picker;
				}
			}
		}
	}
}

.b-store_locator-submit_wrapper {
	.b-store_locator-submit {
		max-width: 50%;

		@include media(sm) {
			display: flex;
			margin: 40px auto 0;
		}
	}
}

.b-store-wrapper {
	max-height: 200px;
	overflow-y: auto;
	.b-store_details {
		margin-bottom: 15px;
		font-size: 22px;
		padding-bottom: 10px;
		border-bottom: 1px solid;
	}
}
.sfcc-store-locator-error {
	margin-top: 8px;
}
