.b-minicart_product {
	@include t-paragraph_2;

	align-items: start;
	display: grid;
	grid-template-columns: [image-s] 100px [image-e] rh(3) [details-s] minmax(0, 1fr) [details-e] rh(4) [remove-s] 22px [remove-e];

	&-image {
		grid-column: image-s/image-e;
	}

	&-picture {
		@include g-product_image;

		img {
			@include g-product_image(img);
		}
	}

	&-details {
		grid-column: details-s/details-e;
	}

	.b-cart_product_details {
		&-name {
			font-size: $configuration-minicart_product-name-font_size;
			line-height: 1.5;
		}
	}

	&-qty {
		margin-top: rh(4);

		.b-product_quantity {
			height: 40px;
			width: 135px;
		}
	}

	&-qty_value {
		font-weight: bold;
	}

	&-subtotal {
		align-items: baseline;
		display: flex;
		flex-wrap: wrap;
		margin-top: rh(3);
	}

	&-subtotal_label {
		margin-right: rh(1);
	}

	&-remove {
		grid-column: remove-s/remove-e;
	}

	&.m-no_edit {
		grid-template-columns: [image-s] 120px [image-e] rh(4) [details-s] minmax(0, 1fr) [details-e];
	}

	&.m-no_edit &-qty_value {
		font-weight: normal;
	}

	&.m-no_edit &-qty_inline {
		text-transform: uppercase;
	}

	&.m-no_edit .b-cart_product_details-attributes {
		font-size: $size-font;
	}
}
