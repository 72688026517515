.b-cart_product_details {
	&-name {
		font: $configuration-cart_product_details-name-font;
		letter-spacing: $configuration-cart_product_details-name-letter_spacing;
		overflow-wrap: break-word;
		text-transform: $configuration-cart_product_details-name-transform;
	}

	&-sku {
		color: $color-product_table-sku;
		font: 14px/1.5 $font-main;
		letter-spacing: 0.2px;
		margin-top: rh(1);
	}

	&-attributes {
		align-items: baseline;
		display: flex;
		flex-wrap: wrap;
		font-size: 16px;
		line-height: 1.5;
	}

	&-attribute {
		align-items: center;
		display: flex;

		&::after {
			background: $color-product_table-attributes_separator;
			content: '';
			height: 12px;
			margin: rh(0 2);
			width: 1px;
		}

		&:last-child::after {
			content: none;
		}

		&.m-qty {
			text-transform: uppercase;

			@include media(lg-up) {
				display: none;
			}
		}
	}

	&-attributes.m-with_qty &-attribute:nth-last-child(2)::after {
		@include media(lg-up) {
			content: none;
		}
	}

	&-qty {
		font-size: 16px;
		line-height: 1.5;
		margin-top: rh(3);
	}

	&-qty_value {
		font-weight: bold;
	}

	&-price,
	&-attributes,
	.b-product_promo,
	.b-product_availability {
		margin-top: rh(3);
	}

	.b-product_promo {
		width: fit-content;
	}

	.b-product_availability {
		align-items: center;
		display: flex;
		font-size: 16px;
		line-height: 1.5;

		&::before {
			margin-right: rh(2);
		}
	}

	.b-product_actions-description {
		font-size: 14px;
	}

	.b-product_actions-description_date {
		font-weight: 700;
	}
}
