/*md

# Link type 5

Another variation of an underlined link

It takes the same list of arguments as `t-link_underlined`
component

```scss_example
.b-some_link {
	@include t-link_5;
}
```
*/

@mixin t-link_5($args...) {
	@include t-link_underlined($args...);

	background-size: 0 0;
}
