.b-account_menu {
	padding: rh(7 5);

	@include media(md-down) {
		background-color: $color-hamburger_account-background;
		border-bottom: 1px solid $color-mobile_navigation-section_divider;
		border-top: 1px solid $color-mobile_navigation-section_divider;
	}

	&-title {
		font-size: 22px;
		line-height: 1;
		margin-bottom: rh(5);
	}

	&-item {
		& + & {
			display: block;
			margin-top: rh(5);
		}
	}
}
