/*

# f-field

Use this component to add some structure to forms.
It is possible to use any form element inside.

## Input element with label

```html_example
	<div class="f-field">
		<div lass="f-input_text">
			<input class="f-input_text-field" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
			<label class="f-input_text-label  m-required" for="input1">
				Enter Promo Code
			</label>
		</div>
	</div>
```

## Input element with required label
```html_example
	<div class="f-field">
		<div class="f-field-caption">Promo Code</div>
		<div lass="f-input_text">
			<input class="f-input_text-field" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
			<label class="f-input_text-label m-required" for="input1">
				Enter Promo Code
			</label>
		</div>
	</div>
```

## Input element with caption
```html_example
	<div class="f-field">
		<div class="f-field-caption">Promo Code</div>
		<div lass="f-input_text">
			<input class="f-input_text-field" type="text" id="input1" placeholder="Promo Code" name="couponCode" required="" value="">
			<label class="f-input_text-label" for="input1">
				Enter Promo Code
			</label>
		</div>
	</div>
```
*/

.f-field {
	margin-bottom: rh(5);

	&-caption,
	&-error {
		font-family: $font-main;
		font-size: 12px;
		font-weight: 600;
		line-height: 16px;
		margin-top: rh(1);
	}

	&-caption {
		color: var(--color-form-caption, $color-form-caption);
	}

	&-error {
		color: var(--color-error, $color-error);
	}

	&.m-valid .f-input_text {
		@include icon(checkmark-plain, $icon-width: 20px, $icon-height: 20px) {
			color: $color-success;
			position: absolute;
			right: rh(3);
			top: 13px;

			@include media(sm) {
				top: 18px;
			}
		}
	}

	&.m-no_margin {
		margin-bottom: 0;
	}

	&.m-gift_message {
		margin-bottom: 0;
		margin-top: rh(5);
		text-align: right;

		.f-input_textarea-label,
		.f-input_textarea-field {
			text-align: left;
		}
	}
}
