/*md

# Separator

It's a full-width horizontal line block

<style>
.b-separator {
	margin: 10px;
}
</style>
```html_example
<hr class="b-separator" />
```
*/

.b-separator {
	@include g-separator($spacing: rh(8));

	border-width: 0 0 1px; // reset hr styles
	margin-top: 0; // reset hr styles
}
