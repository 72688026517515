.l-sitemap {
	display: grid;
	grid-template-columns: 2fr 6fr;

	@include media(md) {
		grid-template-columns: 1fr 6fr;
		grid-column-gap: 10px;
	}

	@include media(sm) {
		display: block;
	}

	&-heading {
		@include t-heading_3;

		padding: 20px 16px 0 16px;

		@include media(md) {
			padding: 40px 10px;
			text-align: center;
		}
	}

	&-content {
		padding: 0 16px;

		@include media(md) {
			padding-left: 10px;
		}

		a.sitemap-text {
			color: $color-primary;
		}
	}

	&_title {
		@include t-heading_5;

		padding: 40px 0 20px;

		@include media(md) {
			@include t-heading_6;
		}

		@include media(sm) {
			padding: 10px 0;
		}
	}

	&_detail {
		padding-top: 75px;

		@include media(sm) {
			padding: 0 16px;
		}
	}

	&_category_container {
		display: grid;
		gap: 10px 10px;
		grid-template-columns: repeat(6, 1fr);

		@include media(md) {
			font-size: x-small;
			column-gap: 10px;
			overflow-wrap: inherit;
		}

		@include media(sm) {
			display: block;
			padding-top: 0;
		}

		&__title {
			text-transform: uppercase;

			@include media(md) {
				padding: 0;
			}
		}

		&__categories {
			line-height: 25px;
		}
	}
}

.customer-service {

	@include media(sm) {
		margin-bottom: 32px;
		border-bottom: 1px solid $color-input-border;
	}

	.title {
		font-size: 18px;
		margin: 40px 0 16px;

		&.contactus-generalinfo {
			font-weight: bold;
			position: relative;
			width: 100%;
			border-top: 1px solid $color-input-border;
			padding: 16px 0;
			margin: 32px 0 0 0;
			text-align: left;

			&::after {
				position: absolute;
				content: '';
				right: 16px;
				top: 20px;
				transform: rotate(45deg);
				height: 12px;
				width: 12px;
				border-bottom: 1px solid $color-primary;
				border-right: 1px solid $color-primary;
			}

			&[aria-expanded="true"] {
				&::after {
					transform: rotate(225deg);
					top: 26px;
				}
			}
		}
	}

	&-content {
		li {
			margin-bottom: 8px;
		}
	}
}

.h-sm-hidden {
	@include media(sm) {
		display: none;
	}
}
