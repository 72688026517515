/*md
@no-stat

# Lines clamp

This mixin is useful for adding ... for text with more than 1 line

## Usage

```scss
.component {
	@include line-clamp;
}
```
*/

@mixin lines-clamp($lines: 3, $line-height: false) {
	-webkit-box-orient: vertical; //stylelint-disable-line property-no-vendor-prefix
	display: -webkit-box; //stylelint-disable-line value-no-vendor-prefix
	-webkit-line-clamp: $lines;
	overflow: hidden;

	@if $line-height {
		max-height: calc(#{$lines} * #{$line-height});
	}
}
