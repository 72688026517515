/*md

# f-textarea

Default textarea element

## Default

```scss_example
	.f-input_textarea {
		@include f-textarea;
	}
```
*/

@mixin f-textarea {
	.f-input_textarea {
		&-field {
			@include f-input_reset;

			border: 1px solid $color-form-input-border;
			border-radius: $global-radius;
			color: $color-form-input;
			font-family: $font-main;
			font-size: 16px;
			letter-spacing: 2.1px;
			line-height: 24px;
			padding: rh(4 5);
			resize: vertical;
			width: 100%;

			&:focus,
			&:hover {
				border-color: $color-form-input;
			}

			&.m-invalid,
			&:disabled {
				border-color: $color-form-input__disabled;
				color: $color-form-input__disabled;
			}
		}

		&-label {
			@include f-label(default);

			margin-bottom: rh(1);
		}

		&.m-required &-label {
			@include f-label(required);
		}
	}
}
