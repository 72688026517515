@keyframes loader_icon-grape {
	0% {
		opacity: 0.2;
	}

	100% {
		opacity: 1;
	}
}

.b-loader {
	align-items: center;
	background: rgba($color-primary-background, 0.7);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: z(loader);

	.b-icon_loader {
		color: $color-primary;
		height: 100px;
		width: 100px;
	}

	&:not(.h-hidden) {
		.b-icon_loader-grape {
			animation: loader_icon-grape 1s infinite alternate ease-in-out;
			opacity: 0;
		}

		.b-icon_loader-branch {
			animation: loader_icon-grape 0.7s forwards ease-in-out;
		}
	}

	@each $index in (1 2 3 4 5 6 7 8) {
		.b-icon_loader-grape:nth-child(#{$index}) {
			animation-delay: -0.3s * $index + 0.3;
		}
	}
}
