.b-reserve_bar{
    &--sizeSelect{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        text-align: center;
        justify-content: center;
        &> p{
            width: 100%;
            font-weight: bold;
            color: $color-text;
        }
    }
    &--productGrid{
        margin-top: 1.5rem;
        &>div{
            display: flex;
            flex-wrap: wrap;
            gap: 1.5rem 1rem;
        }
        .productAvailability{
            width: 100%;
            color: $color-text;
            // text-transform: uppercase;
        }
        .productRetailer{
            padding: 1rem;
            border: 2px solid $color-button_alt-text;
            width: calc(33.333333% - 1rem);

            background-color: $color-button_alt-background;
            transition: all ease-in-out 0.4s;
            cursor: pointer;
            color:$color-button_alt-text;
             @include media(sm) {
                width: 100%;
             }
            &:hover{
                background-color: $color-button_alt__hover-background;
                border-color: $color-button_alt__hover-border;
                color:$color-button_alt__hover-text;
            }
            &.disabled{
                pointer-events: none;
                background: #a1a1aa60;
            }
        }
    }
    &--productDetails{
        display: flex;
        gap: 1rem; 
        flex-wrap: wrap;
        &>div{
            width: 100%;
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;
             @include media(sm) {
                width: 100%;
             }
            *:not(div){
            width: 100%;
            }
            p{
                line-height: 1;
            }
            div{
                display: flex;
                align-items: center;
                width: auto;
            }
        }
        button{
            font-size: 14px;
            font-weight: 800;
            height: 60px;
            letter-spacing: 3px;
            cursor: pointer;
        }
        #errorMessage, span{
            width: 100%;
        }
        .f-input_text-label{
            position: relative;
            top: unset;
            left: unset;
        }
        .f-input_select-field{
            min-width: 40px;
            width:auto;
            padding: 4px;
            height: auto;
            text-align: center;
            cursor:pointer;
            margin-left: 4px;
        }
    }
}

