/*md

# Product badges

## Simple promo badge

```html_example
<div class="b-product_badge m-promo">New</div>
```

## Badge with rating

## Wide Badge with rating

```html_example
	<div class="b-product_badge m-rating_wide">
		<div class="b-product_badge-label">92 Wine Spectator</div>
	</div>
```
*/

.b-product_badge {
	&-label {
		font-size: 14px;
		font-weight: bold;
		grid-area: label;
		letter-spacing: 0.16px;
		line-height: 24px;

		@include media(md-down) {
			font-size: 11px;
		}
	}
	//START rating_points elements
	&-points {
		font-size: 50px;
		line-height: $configuration-product_badge-points-line_height;

		@include media(sm) {
			font-size: 32px;
		}
	}

	&-text {
		font-size: 16px;
		line-height: 1;

		@include media(sm) {
			font-size: 11px;
		}
	}

	&.m-rating_points {
		color: var(--color-badge_2-color, $color-badge_2-color);
		font-family: $font-alt;
		text-align: center;
	}

	// START rating_wide elements
	&.m-rating_wide {
		color: var(--color-badge_2-color, $color-badge_2-color);
	}

	&.m-rating_wide &-label {
		font-size: 16px;
		font-weight: bold;
		line-height: 24px;
	}
	// EO rating_wide elements
	&.m-promo {
		background: var(--color-badge_1-background, $color-badge_1-background);
		border-radius: $configuration-product_badge-border_radius;
		color: var(--color-badge_1-color, $color-badge_1-color);
		font: $configuration-product_badge-font;
		letter-spacing: 0.15px;
		padding: 3px 15px;
		text-align: center;
		width: auto;

		@include media(md-down) {
			font-size: $configuration-product_badge-font_size__small;
			padding: rh(0 2);
		}
	}

	&.m-promo &-label {
		font: $configuration-product_badge-font;

		@include media(md-down) {
			font-size: $configuration-product_badge-font_size__small;
		}
	}

	&.m-rating_short {
		text-align: center;
	}

	&.m-rating_short &-label {
		color: var(--color-badge_2-color, $color-badge_2-color);
		font: normal $size-font $font-alt;
	}

	&.m-rating_short &-value {
		color: var(--color-badge_2-color, $color-badge_2-color);
		font: normal 40px $font-alt;
	}
}
