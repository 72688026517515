.b-login_panel {
	&-title {
		font-family: $font-alt;
		font-size: 22px;
		line-height: 1;

		@include media(lg-up) {
			border-bottom: 1px solid $color-account_panel-divider;
			font-size: 26px;
			margin-bottom: rh(2);
			padding-bottom: rh(7);
		}
	}

	&-row {
		margin-top: rh(5);
	}

	&-link {
		align-items: center;
		display: inline-flex;
		font: 16px/22px $font-main;

		&.m-favorites {
			@include icon(heart, $icon-width: 12px, $icon-height: 11px, $position: after) {
				margin-left: rh(1);
			}
		}

		&.m-favorites_active {
			@include icon(wishlist-active, $icon-width: 12px, $icon-height: 11px, $position: after);
		}

		&.m-sign_in {
			@include media(lg-up) {
				@include g-button(main, default full_width);
			}
		}
	}
}


.event-enquiry {
	&-container {
		@include media(md-up) {
			width: 40%;
		}
		padding: 16px;
        text-align: center;
        margin: auto;

		&-content {
			&-header {
				padding-top: 74px;
				font-size:32px;
				line-height:40px;
				letter-spacing:0.31;
				padding-bottom: 48px;
			}
			&-description {
				padding-bottom:84px;
			}
		}

		&-form {
			text-align:left;

			&-footer {
				&-privacy {
					line-height: 16px;
					font-size: 12px;
					letter-spacing: 0.12px;
				}

				&-submit {
					@include media(md-up) {
						width: 45%;
					}
                    margin: auto;
                    display: flex;
					margin-top: 48px;
				}
			}
		}
	}

	&-confirmation {
		display:none;
		padding: 16px;
		@include media(md-up) {
			width: 40%;
		}
        text-align: center;
        margin: auto;

		&-header {
			padding-top: 74px;
			font-size:32px;
			line-height:40px;
			letter-spacing:0.31;
        	padding-bottom: 32px;
		}
		&-description {
			padding-bottom:54px;
		}
		&-button {
			@include media(md-down) {
				width: 90%;
			}
			width: 52%;
		}
	}
	&-carouselcontainer {
		padding: 0 57px;
		@include media(sm) {
		 padding: 0 19px;
		}
	}
}

.b-social_login_flyout {
	&-header {
		font-size: 13px;
		letter-spacing: 2.4px;
		margin-bottom: rh(6);
		margin-top: rh(6) ;
		text-align: center;
		text-transform: uppercase;

		@include media(sm) {
			margin-bottom: rh(5);
		}
	}

	&-btns {
		display: flex;
	}

	&-btn {
		box-shadow: $depth-0;
		cursor: pointer;
		flex: 1;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 2px;
		padding: rh(3);
		text-align: center;
		text-transform: uppercase;
		transition: $motion-ease;
		transition-property: box-shadow;

		@include media(sm) {
			padding: rh(3 2);
		}

		&:hover {
			box-shadow: $depth-1;
		}

		svg {
			margin-right: rh(2);
		}
	}

	&-btn.m-facebook {
		background-color: $color-social_login__facebook-bg;
		color: $color-social_login__facebook-text;
		margin-right: rh(3);
	}

	&-btn.m-google {
		background: $color-social_login__google-bg;
		border: 1px solid $color-social_login__google-border;
	}

	&-text {
		color: $color-social_login__google-text;
	}
}

.b-complete_profile {
	&-title {
		@include t-heading_3;

		margin-bottom: rh(3);
		margin-top: rh(10);
		text-align: center;

		@include media(sm) {
			margin-bottom: rh(4);
			margin-top: rh(6);
		}
	}

	&-note {
		@include g-section_holder;

		text-align: center;
	}

	&-align_form {
		padding-top: rh(11);
	}
}
