/*md

# b-link_phone

Phone link with **tel:[number]** scheme

```html_example
<a href="tel:1-888-222-3380" class="b-link_phone">1-888-222-3380</a>
```
*/

.b-link_phone {
	white-space: nowrap;

	@include media(sm) {
		text-decoration: underline;
	}

	&:hover {
		@media (hover: hover) and (pointer: fine) {
			text-decoration: underline;
		}
	}

	&-icon {
		margin-right: 5px;
	}
}
