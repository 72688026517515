@mixin g-pd_layout($type) {
	@if $type == wrapper {
		--pd-grid_gap: var(--grid-gutter, 0);
		--pd-grid_gap-sm: var(--pd-grid_gap, 0);

		display: grid;
		gap: var(--pd-grid_gap);
		margin: 0 auto;
		width: calc(var(--pd-layout_width, 100) * 1%);

		@include media(sm) {
			gap: var(--pd-grid_gap-sm);
			width: 100%;
		}

		&.m-grid_width {
			@include g-section_holder;
		}

		&.m-full_width {
			width: 100%;
		}

		&:not(.m-full_width) {
			@include media(xl) {
				max-width: calc(var(--pd-layout_width, 100) / 100 * #{$global-content-width-max});
			}

			@include media(lg) {
				max-width: calc(var(--pd-layout_width, 100) / 100 * #{$global-content-width-max-lg});
			}

			@include media(sm) {
				padding-left: grid-margin(sm);
				padding-right: grid-margin(sm);
			}
		}
	}

	@if $type == cell {
		display: grid;
	}

	@if $type == swap {
		@include media(sm) {
			&:first-child {
				grid-row: 2;
			}

			&:last-child {
				grid-row: 1;
			}
		}
	}
}
