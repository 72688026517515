@import 'common-flagship_tasting_room';

.b-product_actions {
    padding-bottom: 32px;
}

.b-pd_picture-image {
    &-fit-contain {
        object-fit: contain;
    }
}

.b-product_properties-item:last-child {
    border-bottom: 1px solid;
}


.b-product_badge {
	&-image {
		background: none !important;
    }
}

.b-footer-email_subscription {
	+.b-footer-social {
		.b-footer_social {
			padding-top: 0px;
		}
	}
}
