// # Applied colors palette #
//============================================
// ## Main colors ##
$color-primary: #181818 !default;
$color-primary-background: #fff !default;
$color-select-option: #0c0c0c;
$color-error: #CA1E36 !default;//#ba0000 !default;
$color-error-background: #faeded !default;
$color-success: #2e830a !default;
$color-promo-background: #efd57f !default;
$color-notification-background: #ffeeb5 !default;
$color-input-border: #ababab !default;
$color-accent: #790f3d !default;
$color-icon: #ea3323 !default;

// ## Shades ##
$color-shade_1: #252525 !default;
$color-shade_2: #4d4d4d !default;
$color-shade_3: #757575 !default;
$color-shade_4: #d8d8d8 !default;
$color-shade_5: #efefef !default;
$color-shade_6: #fafafa !default;

// ## GH/GF colors ##
$color-header_text: #181818 !default; // to find in the style guide. adjust mapping
$color-header_alt: #fff !default; // to find in the style guide. adjust mapping
$color-footer_text: #fff !default; // to find in the style guide. adjust mapping
$color-footer_alt: #fff !default; // to find in the style guide. adjust mapping

// ## Buttons ##
// ### Button Main ###
$color-button_main-background: $color-primary !default; // add colors to the style guide or completely remove
$color-button_main-border: $color-primary !default;
$color-button_main-text: $color-primary-background !default;
$color-button_main__hover-background: transparent !default;
$color-button_main__hover-border: $color-primary !default;
$color-button_main__hover-text: $color-primary !default;
$color-button_main__inversed-background: $color-primary-background !default;
$color-button_main__inversed-border: $color-primary-background !default;
$color-button_main__inversed-text: $color-primary !default;
$color-button_main__inversed__hover-background: transparent !default;
$color-button_main__inversed__hover-border: $color-primary-background !default;
$color-button_main__inversed__hover-text: $color-primary-background !default;
// ### Alt ###
$color-button_alt-background: transparent !default;
$color-button_alt-border: $color-primary !default;
$color-button_alt-text: $color-primary !default;
$color-button_alt__hover-background: $color-primary !default;
$color-button_alt__hover-border: $color-primary !default;
$color-button_alt__hover-text: $color-primary-background !default;
$color-button_alt__inversed-background: transparent !default;
$color-button_alt__inversed-border: $color-primary-background !default;
$color-button_alt__inversed-text: $color-primary-background !default;
$color-button_alt__inversed__hover-background: $color-primary-background !default;
$color-button_alt__inversed__hover-border: $color-primary-background !default;
$color-button_alt__inversed__hover-text: $color-primary !default;
// ## Badges ##
$color-badge-1: #181818 !default;
$color-badge-2: #807353 !default;
$color-label: #eeeeec;

//============================================

// = HARMONY Palette
// = SEMANTIC COLORS MAPPING TO COMPONENTS
// # COMMON #
$color-highlighter: #3466e5 !default;//focus box component
$color-image-background: $color-shade_6 !default;//background for img tag
$color-overlay-background: #000 !default;//??
$color-scrollbar_thumb: #ababab !default;
$color-box-shadow: #000 !default;//TODO:vu: check with designers

// ## BORDERS ##
$color-border-primary: $color-primary !default;
$color-border-secondary: $color-shade_4 !default;

// ## TEXT ##
$color-text: $color-primary !default;
$color-text__dark: $color-primary !default;
$color-text__light: $color-primary-background !default;
$color-text-dimmed: $color-shade_3 !default;//+
$color-divider: $color-shade_4 !default;
$color-divider-1: $color-primary !default;
$color-notification-text: $color-text__dark !default;

// # COMPONENTS #
// ## SKIP TO CONTENT ##
$color-skip_to-background: #f6f8fe !default;
$color-skip_to-color: $color-text__dark !default;

// ## BACK TO TOP ##
$color-back_to_top-icon-background: $color-primary-background !default;

// ## DIALOG ##
$color-dialog-background: $color-primary-background !default;

// ## LINKS ##
$color-link: $color-primary !default;
$color-link__visited: $color-badge-2 !default; //fix
$color-link__disabled: $color-shade_4 !default; //fix

// ## BREADCRUMBS ##
$color-breadcrumb-current: $color-shade_3 !default;//+
$color-breadcrumb-separator_bg: $color-shade_4 !default;//+
$color-breadcrumb-link_color: $color-primary !default;

// ## REMOVE BUTTON ##
$color-button_remove: $color-primary !default;
$color-button_remove-hover: $color-error !default;

// ## SOCIAL BUTTONS ##
$color-social_login__facebook-bg: #4267b2 !default;
$color-social_login__facebook-text: #fff !default;
$color-social_login__google-bg: #fff !default;
$color-social_login__google-border: $color-primary !default;
$color-social_login__google-text:#181818 !default;

// ## PROGRESS BAR ##
$color-progress-bg: $color-shade_5 !default;
$color-progress-value-bg: $color-primary !default;

// ## MESSAGE ##
$color-message-background: $color-primary-background !default;
$color-message-border: $color-border-secondary !default;
$color-message-text: $color-primary !default;
$color-message-text__inversed: $color-primary-background !default;
$color-message-background__inversed: rgba($color-primary-background, 0.15) !default;

// ## NOTIFICATION ##
$color-notification-text: $color-primary !default;

// ??? statuses
$color-error-background: #faebeb !default;//??
$color-success-obj: rgba($color-success, 0.05) !default;

// ## TABS ##
$color-tabs-border: $color-shade_4 !default;
$color-tabs-text: $color-shade_3 !default;//+
$color-tabs-text__active: $color-primary !default;//+

// ## ACCORDION ##
$color-accordion-item-border: $color-shade_4 !default;

// ## FOOTER ##
$color-footer-border: $color-shade_4 !default;
$color-footer_newsletter-border: $color-input-border !default;
$color-footer-text: $color-footer_text !default;
$color-footer-email_subscription-background: rgba($color-text__dark, 0.78) !default;
$color-footer-background: #6e5222 !default;
$color-newsletter-error-background: rgba($color-primary-background, 0.55) !default;

// ## HEADER ##
$color-header-background: $color-primary-background !default;
$color-header__transparent-text: $color-header_alt !default;
$color-header__transparent__inverted-text: $color-header_text !default;
$color-header__transparent-background-border: rgba($color-primary-background, 0.15) !default;
$color-header_promo-background: $color-promo-background !default;
$color-header__transparent-header_promo-background: $color-badge-2 !default;//+
$color-header__transparent-header_promo-color: inherit !default;//+
$color-navigation_flyout-background: $color-primary-background !default;
$color-hamburger_menu-background: $color-primary-background !default;
$color-hamburger_back_link-background: $color-shade_6 !default;
$color-hamburger_account-background: $color-shade_6 !default;
$color-cookies-background: $color-shade_5 !default;//??
$color-main_nav-divider: $color-shade_5 !default;//??
$color-mobile_navigation-categories_divider: $color-shade_5 !default;//+
$color-mobile_navigation-section_divider: $color-shade_4 !default;

// ### ACCOUNT PANEL ###
$color-account_panel-background: $color-primary-background !default;//+
$color-account_panel-divider: $color-shade_4 !default;

// ### SHIPPING STATES PANEL ###
$color-shipping_panel-background: $color-primary-background !default;//+
$color-shipping_panel__inverse-background: $color-primary !default;//+
$color-browsing_only_note: $color-shade_3 !default;//+
$color-state__hover-background: $color-shade_5 !default;//+
$color-shipping_panel-divider: $color-shade_4 !default;//+
$color-shipping_panel_top-with_image: $color-primary-background !default;

// ### SEARCH PANEL ###
$color-search_panel-background: linear-gradient(180deg, $color-primary-background 23.32%, rgba($color-primary-background, 0.75) 100%) !default;
$color-search_panel-mobile-background: linear-gradient(180deg, $color-primary-background 14.83%, rgba($color-primary-background, 0.9) 100%) !default;
$color-search_panel-suggestions-background: $color-primary-background !default;//+
$color-suggestions_section-mobile-divider: $color-shade_5 !default;//+

// ### MINICART PANEL ###
$color-minicart_panel-background: $color-primary-background !default;
$color-minicart_items-separator: rgba($color-shade_4, 0.8) !default;

// ## CAROUSEL ##
$color-carousel_control: $color-primary !default;//+
$color-carousel_control__inverse: $color-primary-background !default;//+
$color-carousel_pagination_control: $color-shade_4 !default;//+
$color-carousel_pagination_control__active: $color-shade_2 !default;//+
$color-carousel_quote_author: $color-shade_3 !default;//??

// ## FORMS ##
$color-form-input: $color-primary !default;
$color-form-input-background: $color-primary-background !default;
$color-form-input-border: $color-input-border !default;
$color-form-input__disabled: $color-shade_4 !default;
$color-form-label: $color-shade_3 !default;//+
$color-form-caption: $color-shade_3 !default;//+

// ## PRODUCT TILE ##
$color-product_tile-promo-background: $color-promo-background !default;
$color-product_tile-promo: $color-primary !default;
$color-product_tile-description: $color-shade_3 !default;//+
$color-product_tile-error: $color-error !default;//+

// ## PRODUCT PRICE ##
$color-product_price-old: $color-shade_3 !default;//+

// ## PRODUCT BADGE ##
$color-badge_1-background: $color-badge-1 !default;//+
$color-badge_1-color: $color-primary-background !default;//??
$color-badge_2-color: $color-badge-2 !default;//+

// ## PRODUCT WISHLIST CTA ##
$color-wishlist: $color-primary !default;//??
$color-wishlist-active: $color-accent !default;//??

// ## PRODUCT QUEANTITY STEPPER ##
$color-quantity: $color-primary !default;//+
$color-quantity-background: $color-primary-background !default;//+
$color-quantity-picker: #eeeeec;

// # PLP & SEARCH #
$color-filters-border: $color-shade_4 !default;
$color-filters-label: $color-shade_3 !default;//+
$color-sorting-label: $color-shade_3 !default;//+
$color-filter-text: $color-primary !default;//+
$color-search-separator: $color-shade_4 !default;//+

// ## REFINEMENTS ##
$color-refinements-border_color: $color-shade_4 !default;//+
$color-refinements-text: $color-primary !default;//+
$color-refinements-background: $color-primary-background !default;//+
$color-refinements_slide_panel-background: $color-primary-background !default;

// # PDP #
$color-product_tabs-item-border: $color-shade_5 !default;//+
$color-product_tabs-border: $color-shade_4 !default;
$color-product_description: $color-shade_3 !default;//+
$color-product_image-background: $color-shade_6 !default;
$color-product_gallery-thumbs_control-background: rgba($color-shade_5, 0.9) !default;
$color-product_gallery-thumb-border: transparent !default;
$color-product_gallery-thumb__selected-border: $color-primary !default;
$color-sticky-background: $color-primary-background !default;
$color-sticky-background-border: $color-shade_3 !default;

// ## PRODUCT AVAILABILITY  ##
$color-product_availability-in_stock: $color-success !default;//+
$color-product_availability-low_stock: $color-promo-background !default;//+
$color-product_availability-out_of_stock: $color-shade_4 !default;

// ## SWATCHES ##
$color-swatch_title: $color-shade_3 !default;//+
$color-swatch_border: $color-shade_5 !default;//+
$color-swatch_border-selected: $color-primary !default;//+

// # MY ACCOUNT #
$color-account_card-border: $color-shade_4 !default;//+
$color-account_card-thumbnail-text: $color-primary !default;//+
$color-account_card-thumbnail-border: $color-primary !default;//+
$color-account_card-thumbnail__hover-text: $color-primary-background !default;//+
$color-account_card-thumbnail__hover-bg: $color-primary !default;//+
$color-account_info_tile-bg: $color-shade_5 !default;//+
$color-account_info_tile__ghost-bg: linear-gradient($color-primary-background, $color-primary-background) padding-box, repeating-linear-gradient(-45deg, $color-shade_4 0, $color-shade_4 25%, $color-primary-background 0, $color-primary-background 50%) 0 !default;
$color-account_info_tile__ghost__hover-bg: linear-gradient($color-primary-background, $color-primary-background) padding-box, repeating-linear-gradient(-45deg, $color-promo-background 0, $color-promo-background 25%, $color-primary-background 0, $color-primary-background 50%) 0 !default;
$color-navigation_panel-background: $color-primary-background !default;

// ## ACCOUNT NAVIGATION ##
$color-account_nav-item__active-text: $color-primary !default;
$color-account_nav-item__active-bg: $color-shade_5 !default;

// # CART AND CHECKOUT #
// ## PRODUCT TABLE ###
$color-product_table-separator: rgba($color-shade_4, 0.8) !default;
$color-product_table-head_text: $color-shade_3 !default;//+
$color-product_table-sku: $color-shade_3 !default;//+
$color-product_table-attributes_separator: $color-shade_4 !default;

// ## ORDER SUMMARY ##
$color-order_summary-border: $color-shade_4 !default;//+
$color-order_summary-separator: rgba($color-shade_4, 0.8) !default;//+

// ## COUPON FORM ##
$color-coupon_form-border: $color-input-border !default;

// ## CHECKOUT ##
$color-checkout-step: #808080 !default;//+
$color-checkout-switch_name-inactive: $color-shade_3 !default;//+
$color-checkout-card_expired: $color-error !default;
$color-checkout-card_expired-bg: $color-shade_5 !default;//+
$color-checkout_confirmation-account-copy: $color-text__light !default;
$color-checkout_confirmation-account-bg: #6e5222 !default; // color used for case when image is not yet loaded for Create Account on Confirmation page
$color-checkout-card_icon: $color-shade_3 !default;
$color-checkout-gift_message_label: $color-success !default;


// # STORE LOCATOR #
$color-store_locator-border: $color-shade_5 !default;//+
$color-store_locator-background: $color-primary-background !default;//+
$color-store_locator-close_background: $color-shade_6 !default;
$color-store_locator-close_text: $color-primary !default;//+
$color-store_locator-nav_background: $color-primary-background !default;//?
$color-store_locator-nav_text: $color-shade_3 !default;//+
$color-store-border: $color-shade_3 !default;//+
$color-store-hover: $color-shade_5 !default;

// # PAGE DESIGNER #
$color-banner_1_region: $color-primary-background !default;
$color-banner_2_region: $color-primary-background !default;

// # AGE GATE #
$color-age_gate-text: $color-text__light !default;
$color-age_gate-background: transparent !default;

// # ERROR PAGES #
$color-error_410-text: $color-text__dark !default;//+
$color-error_404-text: $color-text__light !default;//+
$color-error_page-background: $color-primary !default;
$color-error_page-text: $color-text__light !default;

// # CONSENT #
$color-age-gate-input: #424546;
$color-background-age-gate-cta: #fff;
$color-border-age-gate-cta: #000;

// # HORIZONTAL FILTER #
$color-horizontal-filter-border: $color-primary;

//# Shipping Promo Colors //
$shipping-promo-progress: linear-gradient(90deg, $color-error-background 0%, $color-accent 100%);
$shipping-promo-base: $color-accent;


