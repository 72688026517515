.b-footer_legal {
	&-links {
		display: flex;
		margin-left: -#{grid-margin(sm)};
		margin-right: -#{grid-margin(sm)};

		@include media(md) {
			flex-wrap: wrap;
			margin-bottom: rh(3);
		}

		@include media(sm) {
			align-items: center;
			flex-flow: column;
			margin: rh(0 0 4);
		}
	}

	&-link {
		@include t-link_5(
			$color: var(--color-footer-text, $color-footer-text),
			$color-visited: $color-footer-text
		);

		font-size: 14px;
		letter-spacing: 0.14px;
		line-height: 22px;
		margin: rh(0 3);

		@include media(md) {
			margin: rh(0 3 3);
		}

		@include media(sm) {
			margin: rh(0 0 4);
		}
	}
}
