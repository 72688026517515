@mixin scrollbar($mod: default) {
	@if $mod == default {
		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}
		// Handle
		&::-webkit-scrollbar-thumb {
			background: $color-scrollbar_thumb;
			border-radius: 3px;
		}
	}

	@if $mod == hidden {
		scrollbar-width: none; // FF specific property

		&::-webkit-scrollbar {
			display: none;
		}
	}
}
