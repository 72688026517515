/*md

# g-dialog_backdrop

Dialog window backdrop styles that used in several component.

```scss_example
.b-menu_panel {
	@include media(sm) {
		@include g-dialog_backdrop;
	}
	// ...
}
```
*/

@mixin g-dialog_backdrop($state: default) {
	@if $state == default {
		bottom: 0;
		display: flex;
		justify-content: center;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		transition: visibility $motion-ease-panels;
		visibility: hidden;
		z-index: var(--dialog-z, 1);

		// the purpose to make it separate is to handle different animation
		// negative position is for fix overlay sliding on mobile fast swipe

		&::after {
			background-color: transparent;
			bottom: -200px;
			content: '';
			left: 0;
			opacity: 0;
			position: fixed;
			right: 0;
			top: -200px;
			transition: $motion-ease-panels;
			transition-property: opacity, background-color;
			z-index: -1; // move to the bottom of the stack context
		}
	}

	@if $state == active {
		overflow-y: scroll;
		visibility: visible;

		&::after {
			background-color: rgba($color-overlay-background, 0.5);
			opacity: 1;
		}
	}
}
