/*md

# f-checkbox

Default checkbox element

## Default

```scss_example
	.b-input_checkbox {
		@include f-checkbox($el: root, $mod: default);
	}
```
*/

@mixin f-checkbox($el: root, $mod: default) {
	@if $el == root {
		.f-input_checkbox {
			align-items: flex-start;
			display: flex;
			width: 100%;

			&-field {
				@include f-checkbox(control);
			}

			&-field:checked,
			&-field.m-checked {
				@include f-checkbox(control, checked);
			}

			&-label {
				@include f-label(default);

				padding: rh(1 0);

				&.m-gift_label {
					color: $color-checkout-gift_message_label;
					font-weight: 700;
					text-transform: uppercase;
				}
			}

			&-field[readonly='readonly'],
			&-field[readonly='readonly'] ~ &-label {
				pointer-events: none;
			}

			&.m-required &-label {
				@include f-label(required);
			}

			&-field:checked + &-label,
			&-field.m-checked + &-label {
				color: $color-form-input;
			}

			&-field:checked + &-label.m-gift_label {
				color: $color-checkout-gift_message_label;
			}

			&-field:disabled {
				@include f-checkbox(control, disabled);
			}

			&-field:disabled + &-label {
				color: $color-form-input__disabled;
				pointer-events: none;
			}
		}
	}

	@if $el == control {
		@if $mod == default {
			@include f-input_reset;
			@include icon($icon_name: checkbox-unchecked checkbox-checked, $icon-width: 20px, $icon-height: 20px, $show: root);

			background: $color-form-input;
			cursor: pointer;
			flex-shrink: 0;
			margin: 3px 10px 0 0;
		}

		@if $mod == checked {
			@include icon($icon_name: checkbox-unchecked checkbox-checked, $show: 2);
		}

		@if $mod == disabled {
			background: $color-form-input__disabled;
			pointer-events: none;
		}
	}
}
