/*md

# f-select

Default label element

## Default

```scss_example
	.f-input_select {
		@include f-select;
	}
```
*/

@mixin f-select_default($_mod: default) {
	@if $_mod == default {
		@include f-input_reset;

		border: 1px solid $color-form-input-border;
		border-radius: $global-radius;
		color: $color-form-input;
		font: $configuration-form-select-font;
		height: $size-input-height;
		letter-spacing: $configuration-form-letter_spacing;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: $configuration-form-select_transform;
		width: 100%;

		@include media(sm) {
			height: $size-input-height-mobile;
		}

		> option {
			background: $color-primary-background;
			color: $color-form-input;
		}
	}

	@if $_mod == required {
		color: $color-text-dimmed;
	}

	@if $_mod == disabled {
		border-color: $color-form-input__disabled;
		color: $color-form-input__disabled;

		&::before {
			color: $color-form-input__disabled;
		}
	}
}

@mixin f-select($_mod: default) {
	@if $_mod == default {
		.f-input_select {
			position: relative;

			@include icon(arrow-down, $icon-width: 15px, $icon-height: 8px) {
				pointer-events: none;
				position: absolute;
				right: 13px;
				top: 50%;
				transform: translate(0, -50%);
			}

			&-field {
				@include f-select_default(default);

				padding: 12px 25px 12px 15px;

				@include media(sm) {
					padding-bottom: 17px;
					padding-top: 17px;
				}

				&:disabled {
					@include f-select_default(disabled);
				}

				&:required:not(.m-valid) {
					@include f-select_default(required);
				}

				&:required option:disabled {
					display: none;
				}
			}
		}
	}

	@if $_mod == alt {
		.f-input_select {
			position: relative;

			@include icon(arrow-down, $icon-width: 15px, $icon-height: 8px) {
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translate(0, -50%);
			}

			&-field {
				@include f-select_default(default);

				border-color: $color-form-input-border;
				border-width: 0 0 1px;
				padding: 12px 15px 12px 0;
			}

			&-field:disabled {
				@include f-select_default(disabled);
			}
		}
	}
}
