.b-store_locator {
	@include g-section_holder;

	&-title {
		@include t-heading_1;

		margin: rh(12 0 6);
		text-align: center;
	}

	&-description,
	&-text {
		max-width: 680px;
		text-align: center;
	}

	&-description {
		@include t-paragraph_2;

		margin: 0 auto rh(12);
	}

	&-text {
		@include t-paragraph_3;

		margin: 0 auto rh(24);
	}

	&-submit {
		@include g-button(main, default full_width);

		margin-top: rh(8);
	}

	&-wrapper {
		column-gap: var(--grid-gutter);
		display: grid;
		grid-template:
			[start form-s separator-s content-s]
			1fr //1
			[end form-e separator-e content-e]
			/
			[start]
			1fr //1
			[form-s]
			1fr //2
			[]
			1fr //3
			[]
			1fr //4
			[]
			1fr //5
			[form-e separator-s]
			1fr //6
			[separator-e]
			1fr //7
			[content-s]
			1fr //8
			[]
			1fr //9
			[]
			1fr //10
			[]
			1fr //11
			[content-e]
			1fr //12
			[end];
		margin-bottom: rh(12);
		position: relative;

		@include media(md) {
			grid-template:
				[start form-s separator-s content-s]
				1fr //1
				[end form-e separator-e content-e]
				/
				[start form-s]
				1fr //1
				[]
				1fr //2
				[]
				1fr //3
				[]
				1fr //4
				[]
				1fr //5
				[form-e separator-s]
				1fr //6
				[separator-e]
				1fr //7
				[content-s]
				1fr //8
				[]
				1fr //9
				[]
				1fr //10
				[]
				1fr //11
				[]
				1fr //12
				[end content-e];
		}

		@include media(sm) {
			grid-template:
				[start form-s]
				auto //1
				[form-e separator-s]
				70px //2
				[separator-e]
				auto //3
				[content-s]
				auto //4
				[end content-e]
				/
				[start form-s separator-s content-s]
				1fr //1
				[]
				1fr //2
				[]
				1fr //3
				[]
				1fr //4
				[end form-s separator-s content-s];
			margin-bottom: rh(7);
		}

		&::before {
			background: $color-search-separator;
			content: '';
			height: 100%;
			left: 50%;
			position: absolute;
			width: 1px;

			@include media(sm) {
				grid-area: separator-s / separator-s / separator-e / separator-e;
				height: 1px;
				left: auto;
				top: 50%;
				width: 100%;
			}
		}
	}

	&-form {
		grid-area: form-s / form-s / form-e / form-e;
	}

	&-content {
		grid-area: content-s / content-s / content-e / content-e;
	}

	&-link {
		@include t-link_3;

		font-size: 16px;
	}

	&-section {
		border: 1px solid $color-store_locator-border;
		display: grid;
		gap: rh(2 4);
		grid-template:
			'image content'
			'image link'
			/ 60px 1fr;
		margin-bottom: rh(4);
		padding: rh(4);
	}

	&-section_image {
		grid-area: image;
	}

	&-section_description {
		@include t-paragraph_3;

		grid-area: content;
	}

	&-section_link {
		grid-area: link;
	}

	.f-input_text {
		margin: rh(5 0);
	}

	.f-input_select::before {
		top: rh(5);
	}

	/* stylelint-disable */
	input.parsley-error,
	select.parsley-error,
	textarea.parsley-error {
		background-color: $color-form-input-background;
		border-color: $color-form-input-border;
		color: inherit;
	}

	input.parsley-success,
	select.parsley-success,
	textarea.parsley-success {
		background-color: $color-form-input-background;
		border-color: $color-form-input-border;
		color: inherit;
	}
	/* stylelint-enable */
	.parsley-errors-list {
		color: $color-error;
		font-size: 12px;
		font-weight: 600;
		line-height: 16px;
		margin-top: 8px;
	}
	#acctGroup{
		margin: rh(5 0);
		fieldset{
			border: none;
			display: flex;
			gap: rh(5);
		}
		.acctGrpWrapper{
			display: flex;
			align-items: center;
		}
	}
}

#brandSelection {
	margin-bottom: 25px;
	max-width: 32.6%;
	margin-left: 8.3%;
	.flavorSelect {
		padding: 5px 20px;
	}
}

#brandSelection {
	@include media(sm){
		margin-left: 0 !important;
		max-width: none;
	}
}

#brandSelection {
	@media (min-width: 767px) and (max-width: 1200px) {
		margin-left: 0 !important;
		max-width: 40.8%;
	}
}
