/*md

# g-accordion

Accordion styles that used in several component.

## Customization by SCSS

This implementation allow to use accordion for one vieport and any other component for rest viewports

```scss_example
.g-accordion {
	@include g-accordion;

	&-item {
		@include g-accordion(item);
	}

	&-title {
		@include g-accordion(control);
	}

	&-content {
		@include g-accordion(content);

		&[aria-hidden='false'] {
			@include g-accordion(content, expanded);
		}
	}

	&-content_inner {
		@include g-accordion(content_inner);
	}
}
```

*/

@mixin g-accordion(
	$_: root,
	$_mod: default
) {
	@if $_ == item {
		border-bottom: 1px solid $color-accordion-item-border;
	}

	@if $_ == horizontalitem {
		border-bottom: 0;
	}

	@if $_ == control {
		@if $_mod == default {
			@include icon($icon_name: plus minus, $position: after, $icon-width: 14px, $icon-height: 14px);

			align-items: center;
			cursor: pointer;
			display: flex;
			font-size: 20px;
			justify-content: space-between;
			letter-spacing: 0.3px;
			line-height: 24px;
			padding: rh(6 0);
			width: 100%;
		}

		@if $_mod == opened {
			&::after {
				@include icon($icon_name: plus minus, $show: 2);
			}
		}
	}

	@if $_ == horizontalfilter {
		@include icon(arrow-down, $position: after, $icon-width: 14px, $icon-height: 8px) {
			margin-right: 12px;
		}

		@if $_mod == default {
			align-items: center;
			cursor: pointer;
			display: flex;
			font-size: 20px;
			justify-content: space-between;
			letter-spacing: 0.3px;
			line-height: 24px;
			width: 100%;
		}

		@if $_mod == opened {
			border-bottom: 0;
		}
	}

	@if $_ == horizontalcontent {
		@if $_mod == default {
			max-height: 0;
			overflow: hidden;
			padding: 0 12px;
			transition: max-height 0.2s ease-in-out;
		}

		@if $_mod == expanded {
			animation: hide-scroll 0.2s backwards;
			background: $color-navigation_flyout-background;
			border: 1px solid $color-horizontal-filter-border;
			border-top: 0;
			max-height: 325px;
			overflow: auto;
		}

		@keyframes hide-scroll {
			from,
			to {
				overflow: hidden;
			}
		}
	}

	@if $_ == content {
		@if $_mod == default {
			height: 0;
			opacity: 0;
			overflow: hidden;
			position: relative;
			transition: $motion-fast;
			transition-property: height, opacity;
			visibility: hidden;
		}

		@if $_mod == expanded {
			height: auto;
			opacity: 1;
			visibility: visible;
		}
	}

	@if $_ == content_inner {
		overflow: hidden;
		padding: 0 0 25px;
	}
}
