@mixin g-product_image($el: container) {
	@if $el == container {
		@include g-image_container(_container, aspect-ratio(3, 4)) {
			background: $color-product_image-background;
		}
	}

	@if $el == img {
		@include g-image_container(_img);
	}
}
