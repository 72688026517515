/* stylelint-disable declaration-no-important */
.h-hide {
	@each $queryName, $queryValue in $media {
		&-#{string-replace($queryName, '-', '_')} {
			@media #{$queryValue} {
				display: none !important;
			}
		}
	}

	&-text {
		font-size: 0;
	}
}

.h-hidden {
	display: none !important;
}

.h-hide_visually {
	@include hide(visually);
}

.h-show_only {
	@each $queryName, $queryValue in $media {
		&-#{string-replace($queryName, '-', '_')} {
			@media not #{$queryValue} {
				display: none !important;
			}
		}
	}
}
/* stylelint-enable declaration-no-important */
