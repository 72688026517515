.b-contact_us {
	@include g-section_holder;

	margin-top: rh(12);

	&-inner {
		margin: 0 auto;
		max-width: 450px;
	}

	&-form {
		margin-top: rh(6);

		> .f-input_text,
		.f-input_textarea,
		.f-input_checkbox,
		.f-input_field {
			margin-bottom: rh(4);
		}

		#genderWrapper { // hide gender select control
			display: none !important; //stylelint-disable-line
		}
	}

	&-form_title {
		@include t-heading_6;

		margin-top: rh(6);
	}

	&-form_label {
		@include f-label(default);

		margin-bottom: rh(1);
	}

	&-form_link {
		@include t-link_2;

		font-weight: normal;
	}

	&-form_paragraph {
		@include t-paragraph_2;

		margin-bottom: rh(4);
	}

	&-caption_image {
		margin-top: rh(1);
	}

	&-birth_group {
		display: grid;
		grid-gap: rh(3);
		grid-template-columns: repeat(3, 1fr);
	}

	&-phone_group {
		display: grid;
		grid-gap: rh(3);
		grid-template-columns: 2fr 1fr;

		@include media(sm) {
			grid-template-columns: 1fr;
		}
	}

	&-contact_group {
		display: flex;

		.f-input_radio {
			margin-right: rh(3);
			width: auto;
		}
	}

	.f-input_select::before {
		top: rh(5);

		@include media(sm) {
			top: rh(6);
		}
	}

	.f-input_checkbox-label {
		display: block;
	}

	&-purchase_date_label {
		@include media(sm) {
			transform: translateY(-20%);
		}
	}

	.f-input_select-field,
	.f-input_text-field,
	.f-input_textarea-field {
		&.parsley-error,
		&.parsley-success {
			background-color: $color-form-input-background;
			border-color: $color-form-input-border;
			color: inherit;
		}
	}

	.parsley-errors-list {
		color: $color-error;
		font-size: 12px;
		font-weight: 600;
		line-height: 16px;
		margin-top: 8px;
	}

	.f-input_text-field[style*='display'] {
		+ .parsley-errors-list,
		~ .f-input_text-label {
			display: none;
		}
	}
}

/* stylelint-disable */
#thankyou-overlay {
	position: fixed;
	top: 0;
}

#thankyou-overlayContent {
	border: none;
	border-radius: 0;
	margin: 0;
	padding: 50px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);

	@include media(sm) {
		height: auto !important; // override third party styles
		width: 100%;
	}

	@include icon(close, $icon-width: 21px, $icon-height: 21px) {
		cursor: pointer;
		position: absolute;
		right: rh(6);
		top: rh(6);

		@include media(sm) {
			height: 16px;
			right: rh(3);
			top: rh(3);
			width: 16px;
		}
	}

	#msg {
		@include t-heading_3;

		color: inherit;
		padding: 0;

		.txt {
			color: inherit;
		
		}
		#errorTxt.txt{
				font-size: 16px;
				line-height: 28px;
		}
	}
}
/* stylelint-enable */
