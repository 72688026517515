.b-header_message {
	bottom: 0;
	font-family: $font-main;
	font-size: 16px;
	letter-spacing: 0.2px;
	line-height: 24px;
	padding: rh(6 3);
	position: fixed;
	text-align: center;
	width: 100%;
	z-index: z(js-disabled);

	&-link {
		@include t-link_2;

		font-weight: 400;
	}

	&.m-javascript {
		background: $color-error-background;
		color: $color-error;
	}

	&.m-cookies {
		background: $color-cookies-background;
		color: $color-message-text;
	}
}
