/*

# Form

TBD

*/

.b-form {
	&-message {
		@include g-message;

		margin-bottom: rh(4);
	}

	&-message.m-inversed {
		@include g-message(inversed);
	}

	&-message.m-error {
		@include g-message(error);

		margin-bottom: rh(7);
	}

	&-set {
		border: 0;
		margin: rh(4 0);
		padding: 0;

		&[hidden] {
			display: none;
		}

		&.m-gift_message {
			margin: 0;
		}

		.b-expected_ship_date {
			margin-top: rh(4);
		}
	}

	&-set_label.m-wai {
		@include hide(visually, true);
	}

	&-note {
		margin-bottom: rh(4);
	}

	&-line {
		display: flex;
		width: 100%;
		@include media(sm) {
            display: block;
            .f-field {
                margin-right: 0
            }
        }
		.f-field {
			flex: 1;
			margin-right: rh(3);

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&-fixed_wrapper {
		max-width: 100%;
	}

	&-field_group {
		display: inline-block;
		margin-bottom: rh(4);
		width: 100%;
	}

	&-static_row {
		border-bottom: 1px solid $color-divider;
		font-size: 18px;
		letter-spacing: 0.2px;
		margin-bottom: rh(8);
		padding-bottom: rh(4);
	}

	&-static_row_label {
		font-weight: 700;
	}

	&-controls {
		display: flex;
	}

	&-controls.m-column {
		flex-direction: row;
		.b-form-btn_alt {
			margin-top: 0;
			margin-left: 18px;
		}
	}

	&-btn_main {
		@include g-button(main, default full_width);
	}

	&-btn_main.m-inversed {
		@include g-button(main, inversed);
	}

	&-btn_alt {
		@include g-button(alt, default full_width);
	}

	&-btn_alt.m-inversed {
		@include g-button(alt, inversed);
	}

	&-btn_main + &-btn_alt {
		margin-top: rh(5);
	}

	&-back {
		cursor: pointer;

		@include icon(arrow-left, $icon-width: 8px, $icon-height: 14px) {
			display: inline-block;
			margin-right: rh(2);
			vertical-align: middle;
		}
	}

	&-set.m-no_margin {
		margin: 0;
	}
}
