$MAP: $z-indexes;

// stylelint-disable

@function _z-index($keys) {
	$map: $MAP;
	$found-index: null;

	@each $key in $keys {
		@if (type-of($map) == 'map') {
			$found-index: index(map-keys($map), $key);
			$map: map-get($map, $key);
		} @else {
			$found-index: index($map, $key);
		}
	}

	@return $found-index;
}

@function z($keys...) {
	@return _z-index($keys);
}
// stylelint-enable
