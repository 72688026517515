@mixin g-overlay {
	background-color: rgba($color-overlay-background, 0.5);
	bottom: 0;
	content: '';
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;

	@content;
}
