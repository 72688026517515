$pd_margins: (
	xxxl: (
		lg-up: 140px,
		md-down: 100px,
	),
	xxl: (
		lg-up: 90px,
		md-down: 70px,
	),
	xl: (
		lg-up: 60px,
		md-down: 50px,
	),
	l: (
		lg-up: 40px,
		md-down: 35px,
	),
	m: (
		lg-up: 30px,
		md-down: 25px,
	),
	s: (
		md-up: 20px,
		sm: 15px,
	),
	xs: 10px,
	xxs: 5px,
	zero: 0,
);

body {
	@include responsive-var-generator($pd_margins);
}
