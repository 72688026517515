.b-social_share{
    display: flex;
    align-items: center;
    gap: rh(2);
    margin-left: auto;
    margin-right: auto;
    flex-wrap:wrap;
    width: var(--component-width);
    #st-2 .st-btn, #st-2 .st-btn[data-network]{
        background-color: var(--colorOverride,  $color-button_main-background) !important;
        display: inline-block !important;
    }
}