/* stylelint-disable selector-no-qualifying-type */
body {
	--grid-gutter: #{grid-gutter('xl')};
	--grid-margin: #{grid-margin('xl')};
	--grid-columns: #{grid-columns('xl')};

	padding: var(--header-height) 0 0;

	@include media(lg) {
		--grid-gutter: #{grid-gutter('lg')};
		--grid-margin: #{grid-margin('lg')};
		--grid-columns: #{grid-columns('lg')};
	}

	@include media(md) {
		--grid-gutter: #{grid-gutter('md')};
		--grid-margin: #{grid-margin('md')};
		--grid-columns: #{grid-columns('md')};
	}

	@include media(sm) {
		--grid-gutter: #{grid-gutter('sm')};
		--grid-margin: #{grid-margin('sm')};
		--grid-columns: #{grid-columns('sm')};
	}

	&.m-transparent_header {
		padding: 0;
	}

	&.m-panel_opened {
		overflow: hidden;
	}
}

html {
	&.m-header-hamburger_opened,
	&.m-age_gate-open {
		height: 100vh;
		overflow: hidden;
	}

	&.m-age_gate-open {
		.b-skip_to,
		.b-header,
		.l-page-content,
		.l-page-footer,
		.b-back_to_top {
			display: none;
		}
	}

	&:not(.m-age_gate-open) {
		.b-age_gate {
			display: none;
		}
	}
}

q {
	quotes: '\201c' '\201d';
}

/* stylelint-enable selector-no-qualifying-type */
.l-page.m-has_dialog,
.l-page.m-panel_opened {
	.b-header-navigation_inner {
		@include media(md-down) {
			left: -100%;
			opacity: 1;
			overflow: visible; // iOS issue with overflow hidden and position fixed elements
		}
	}
}

@include media(lg-up) {
	@include scrollbar;
}
