/*md

# Button Main

## States

### Normal state

```html_example
<button class="g-button_main">Button</button>
```

### Disabled state

There are two variants of disabled buttons:

- disabled with CSS class (only disabled look, can be focused)
- disabled with HTML attribute

```html_example
<button class="g-button_main m-disabled">Disabled (CSS)</button>
<button class="g-button_main" disabled>Disabled (HTML)</button>
```

## Modifications

### Small

```html_example
<button class="g-button_main m-small">Small button</button>
```

### Full width

Is the same as the default one but with 100% width

```html_example
<button class="g-button_main m-full_width">Full width</button>
```

### Inversed

The same type of button but with inversed colors

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_main m-inversed">Inversed</button>
</div>
```

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_main m-inversed m-small">Small</button>
</div>
```

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_main m-inversed m-disabled">Disabled (CSS)</button>
	<button class="g-button_main m-inversed" disabled>Disabled (HTML)</button>
</div>
```

```html_example
<div style="background: #ABABAB; padding: 15px;"> <!-- Helper for visualization (not required) -->
	<button class="g-button_main m-inversed m-full_width">Full width</button>
</div>
```
*/

.g-button_main {
	@include g-button_main;

	&.m-inversed {
		@include g-button_main(inversed);
	}

	&.m-full_width {
		@include g-button_main(full_width);
	}
}
