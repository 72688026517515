/*md

# Headings

## Heading 1

```html_example
<h1 class="t-heading_1">Heading 1</h1>
```
## Heading 2

```html_example
<h2 class="t-heading_2">Heading 2</h2>
```
## Heading 3

```html_example
<h3 class="t-heading_3">Heading 3</h3>
```
## Heading 4

```html_example
<h4 class="t-heading_4">Heading 4</h4>
```
## Heading 5

```html_example
<h5 class="t-heading_5">Heading 5</h5>
```
## Heading 6

```html_example
<h6 class="t-heading_6">Heading 6</h6>
```

# Paragraphs

## Paragraphs 1

```html_example
<p class="t-paragraph_1">Paragraph 1</p>
```

## Paragraphs 2

```html_example
<p class="t-paragraph_2">Paragraph 2</p>
```

## Paragraphs 3

```html_example
<p class="t-paragraph_3">Paragraph 3</p>
```

# Links

## Link 1

```html_example
<a href="#" class="t-link_1">Link 1</a>
```
## Link 2

```html_example
<a href="#" class="t-link_2">Link 2</a>
```
## Link 3

```html_example
<a href="#" class="t-link_3">Link 3</a>
```
## Link 4 (link with icon)

### Link 4 (edit)

```html_example
<a href="#" class="t-link_4 m-edit">Edit link</a>
```

### Link 4 (remove)

```html_example
<a href="#" class="t-link_4 m-remove">Remove link</a>

```

### Link 4 (grape via inline svg)

You can control icons dimensions (height and width) using `style` attribute and
`--link-icon_height`, `--link-icon_width` CSS custom properties.

Check the examples below.

Inlined SVG variant:

```html_example
<a href="#" class="t-link_4" style="--link-icon_height: 30px; --link-icon_width: 30px">
	<svg class="b-icon_loader" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" viewBox="0 0 36.8 61">
		<path class="b-icon_loader-grape m-1" d="M18.4 22.6a6 6 0 11-6-5.9 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-4" d="M24.3 33.4a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-3" d="M12.4 33.4a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-5" d="M36.3 33.4a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-2" d="M30.3 22.6a6 6 0 11-6-5.9 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-7" d="M30.3 43.9a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-6" d="M18.4 43.9a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-grape m-8" d="M24.3 54.5a6 6 0 11-6-6 6 6 0 016 6z"/>
		<path class="b-icon_loader-branch" d="M18.4 22.6S17.6 6.5 25.7.5M19.8 10.3A5.9 5.9 0 0015.5 3a4.7 4.7 0 00-5.7 3.5 3.8 3.8 0 002.7 4.6 3 3 0 003.7-2.3"/>
	</svg>
	Grape icon link
</a>
```

The same result can be achieved in content assets
with less code
(using `$tools` helper)

```
<a href="#" class="t-link_4" style="--link-icon_height: 30px; --link-icon_width: 30px">
	$tools.icon('loader')
	Grape icon link
</a>
```

## Link 5

```html_example
<a href="#" class="t-link_5">Link 5</a>
```

# Lists

## Unordered List

```html_example
<ul class="t-unordered_list">
	<li>item 1</li>
	<li>item 2</li>
	<li>item 3</li>
	<li>item 4</li>
</ul>
```

## Ordered List

```html_example
<ol class="t-ordered_list">
	<li>item 1</li>
	<li>item 2</li>
	<li>item 3</li>
	<li>item 4</li>
</ol>
```

*/

.t-heading_1 {
	@include t-heading_1;
}

.t-heading_2 {
	@include t-heading_2;
}

.t-heading_3 {
	@include t-heading_3;
}

.t-heading_4 {
	@include t-heading_4;
}

.t-heading_5 {
	@include t-heading_5;
}

.t-heading_6 {
	@include t-heading_6;
}

// Paragraphs
.t-paragraph_1 {
	@include t-paragraph_1;
}

.t-paragraph_2 {
	@include t-paragraph_2;
}

.t-paragraph_3 {
	@include t-paragraph_3;
}

.t-paragraph_4 {
	@include t-paragraph_4;
}

// Links
.t-link_1 {
	@include t-link_1;
}

.t-link_2 {
	@include t-link_2;
}

.t-link_3 {
	@include t-link_3;
}

.t-link_4 {
	@include t-link_4;
}

.t-link_4.m-edit {
	@include t-link_4('edit');
}

.t-link_4.m-remove {
	@include t-link_4('remove', $icon-height: 0.9em);
}

.t-link_5 {
	@include t-link_5;
}

// Lists
.t-unordered_list {
	@include t-list;
}

.t-ordered_list {
	@include t-list(ordered);
}
