.b-shipping_states {
	height: 100%;
	overflow-y: hidden;
	padding: 10px 5px 30px 8px;
	position: relative;

	&::after {
		background: linear-gradient(180deg, rgba($color-shipping_panel-background, 0) 0%, $color-shipping_panel-background 84.81%);
		bottom: 0;
		content: '';
		height: 144px;
		left: 0;
		max-height: 15vh;
		pointer-events: none;
		position: absolute;
		right: 0;
	}

	&-inner {
		height: 100%;
		overflow-y: auto;
		padding-bottom: 114px;
	}

	&-item {
		position: relative;
		&.browsing{
			opacity: 0;
			visibility: hidden;
			height:0;
		}
	}

	&-radio {
		height: 36px;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		width: 100%;
		z-index: -1; // Input element should be behind label element
	}

	&-label {
		cursor: pointer;
		display: block;
		font-size: 16px;
		line-height: 36px;
		padding-left: rh(7);
		transition: background-color 0.3s;

		&:hover {
			background-color: $color-state__hover-background;
		}
	}

	&-radio:checked + &-label {
		font-weight: bold;
		position: relative;

		@include icon(checkmark-plain, $icon-width: 13px, $icon-height: 10px) {
			position: absolute;
			right: 44px;
			top: 13px;
		}
	}

	&-label_note {
		color: $color-browsing_only_note;
		font-size: 12px;
		margin-left: rh(2);
	}
}
