// $pd_sizes: ( // map to pass to mixin
//     xxxl: ( // custom property name
//         l-up: 140px, // media: value of custom property on that media
//         m-down: 100px,
//     ),
//     xxl: (
//         l-up: 90px,
//         m-down: 70px,
//     ),
//     xl: (
//         l-up: 60px,
//         m-down: 50px,
//     ),
//     l: (
//         l-up: 40px,
//         m-down: 35px,
//     ),
//     m: (
//         l-up: 30px,
//         m-down: 25px,
//     ),
//     s: (
//         m-up: 20px,
//         s: 15px,
//     ),
//     xs: 10px,
//     xxs: 5px,
// );

@mixin responsive-var-generator($map) {
	@each $custom_property, $custom_property_value in $map {
		@if type-of($custom_property_value) == 'map' {
			@each $media, $value in $custom_property_value {
				@include media(#{$media}) {
					--#{$custom_property}: #{$value};
				}
			}
		} @else {
			--#{$custom_property}: #{$custom_property_value};
		}
	}
}
