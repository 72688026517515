.b-reserve_bar{
    &--search{
        width: var(--component-width, 100%);
        @include media(sm) {
            width: 100%;
        }
        input,label{
            width: 100%;
            color: inherit
        }
        input{
            font: $configuration-form-input-font;
			height: $size-input-height;
			letter-spacing: 2.1px;
			line-height: 22px;
			transition: height linear 0.5s;
            appearance: none;
            background: var(--searchBackgroundColor, transparent);
            color: var(--searchColor, inherit);
            border-radius: 0;
            min-width: 0;
            vertical-align: initial;
            border: 2px solid var(--searchBorderColor, $color-form-input-border);
            padding: 14px 40px 14px 15px;
            text-align: center;
            &:hover{
                border: 2px solid var(--searchBorderColor, $color-form-input-border);
            }
			@include media(sm) {
				height: $size-input-height-mobile;
			}

        }
        label{
            font: $configuration-form-input-font;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
            display: block;
        }
        ul{
            opacity: 0;
            &.results{
                opacity:1;
                border: 1px solid $color-form-input-border;
                border-top: none;
                padding: 1rem;
            }

            li{
                @include icon(location, $icon-width: 13px, $icon-height: 17px);
                &:before{
                    background: var(--icon-color,$color-shade_1)
                }
                display: flex;
                align-items: center;
                gap: 1rem;
                font-size: 18px;
			
            }
        }
    }

}