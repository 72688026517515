.b-reserve_bar{
    &--cart{
        &>div{
            display: flex;
        }
    }
}

.b-rb_cart{
	position: relative;
	z-index: 10;
	&--bg{
		// position: fixed;
		inset: 0px;
		background:transparent;
		transition: all ease 0.5s;
		&.show{
			position: fixed;
			background: rgba(0,0,0, 0.15);
		}
		
	}
	&--inner{
		// position: fixed;
		inset: 0px;
		overflow: hidden;
	}
	&--container{
		position: absolute; 
		inset: 0px; 
		overflow: hidden;
	}
	&--column{
		pointer-events:none;
		position:fixed;
		top: 0px;
		bottom: 0px;
		right: 0;
		display: flex;
		max-width: 100%;
		padding-left: 2.5rem;
	}
	&--drawer{
		pointer-events:none; 
		width: 100vw; 
		max-width: 28rem;
		transition: all ease-in-out 0.5s;
        display: flex; 
        height: 100%;  
        flex-direction: column; 
        overflow-y: scroll; 
        background-color: $color-primary-background;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
		box-shadow: 0 0 $color-primary, 0 20px 25px -5px RGB(0, 0, 0 , 0.1), 0 8px 10px -6px RGB(0, 0, 0,  0.1), 0 0 $color-primary;
		&.translate-x-0{
			pointer-events: all;
		}
		&__header{
            h2{
                font-size: 2rem;
            }
                
				&>div:first-child{
					padding-left: 1rem;
					padding-right: 1rem;
                    
					@include media(sm){
						padding-left: 1.5rem;
						padding-right: 1.5rem;
					}
					div:not(.b-rb_cart--drawer__close){
						display: flex; 
						align-items: flex-start;
						justify-content: space-between;
                        border-bottom: 1px solid $color-primary;
					}
				}
			}
			&__close{
				display: flex;
				align-items: center;
				margin-left: 0.75rem;
				height: 1.75rem;
				button{
					position:relative;
					z-index: 50;
					cursor: pointer;
				}
				span{
					position: absolute;
					inset: -0.625rem;
				}
				svg{
					height: 1.5rem;
					width: 1.5rem;
				}
			}

		}
        &--list{
				position: relative;
				flex: 1 1 0%;
				padding-left: 2rem;
				padding-right: 2rem;
				margin-top: 1.5rem;
					@include media(sm){
						padding-left: 1.5rem;
						padding-right: 1.5rem;
					}
				&__inner{
                    
                    &>div{
                        display: flex;
                        flex-wrap:wrap;
                        margin-bottom: 1rem;
                    }
                }
                &__item{
                    width: 100%;
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                    border-bottom: 1px solid $color-primary;
                    padding: 1rem 0;
                    position: relative;
                    img{
                        object-fit: contain;
                        width: 100px;
                        height: 100px;
                    }
                    button{
                        position: absolute;
                        left: 98%;
                        top: 0;
                        width: 15px;
                        height: 15px;
                        cursor:pointer;
                    }
                    .cartQty{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0.5rem;
                        select{
                            cursor: pointer;
                        }
                    }
                    .volume{
                        font-style: italic;
                    }
                    p:not(.cartQty){
                        font-size: rem(12px);
                    }
                }
                button{
                     &.g-button_alt, &.g-button_main{
                        width: 100%;
                     }
                    
                    &.g-button_alt{
                        margin-top: 1.5rem;
                    }
                }
			}
            &--qty{
                color: $color-primary-background;
                background: $color-primary;
                padding: 1px 6px;
                border-radius: 50%;
                font-size: 10px;
                position: absolute;
                left: calc(100% - 16px);
                top: 5px;
                display: none;
                &.show{
                    display: block;
                }
            }
            &--subtotal{
                display: flex;
                justify-content: space-between;
                font-size: 1.25rem;
            }
            &--disclaimer{
                margin-top: 1rem;
            }
	}

    .translate-x-0 {
  transform: translateX(0px);
}

.translate-x-full {

  transform: translateX(100%);
}
.relative{
    position: relative;
}