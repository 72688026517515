.b-footer_social {
	&-title {
		font: bold 16px/24px $font-main;
		letter-spacing: 2.4px;
		margin-bottom: rh(3);
		text-transform: uppercase;
	}

	&-links {
		display: flex;
		justify-content: flex-start;
		column-gap: 16px;
		@include media(sm){
			justify-content: center;
		}
	}

	&-link {
		&.m-twitter {
			@include icon(twitter, $icon-width: rh(9), $icon-height: rh(9));
		}

		&.m-instagram {
			@include icon(instagram, $icon-width: rh(9), $icon-height: rh(9));
		}

		&.m-facebook {
			@include icon(facebook, $icon-width: rh(9), $icon-height: rh(9));
		}

		&.m-pinterest {
			@include icon(pinterest, $icon-width: rh(9), $icon-height: rh(9));
		}

		&.m-youtube {
			@include icon(youtube, $icon-width: rh(9), $icon-height: rh(9));
		}
	}
}
