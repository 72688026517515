/*md

# b-product_price*

Some example of product price structure.

```html_example
	<div class="b-product_price">
		<div class="b-product_price-value m-old">
			$14,99
		</div>
		<div class="b-product_price-value">
			$10,99
		</div>
	</div>
```

*/

.b-product_price {
	display: flex;
	flex-wrap: wrap;
	font: $configuration-product_price-font;
	letter-spacing: 0.3px;

	&-value {
		word-break: keep-all;
		position: relative;

		&.m-old {
			color: var(--color-product_price-old, $color-product_price-old);
			margin-right: rh(2);
			text-decoration: line-through;
			position: relative;
		}

		&.m-sales {
			position: relative;
		}
	}
}

.sr-only-element {
	opacity: 0;
	position: absolute;
	height: 1px;
}
