.b-minicart_popup {
	font-size: 0;

	&-button,
	&-link {
		@include icon(bag, $icon-width: 13px, $icon-height: 16px);

		align-items: flex-start;
		cursor: pointer;
		display: flex;
		padding: rh(3); // to increase clickable area

		@include media(md-down) {
			padding: rh(2); // to increase clickable area
		}

		&:hover {
			text-decoration: none;
		}
	}

	&-qty {
		font-size: 14px;
		letter-spacing: 0.7px;
		line-height: 19px;
		margin-left: rh(1);
	}
}
