/*md

# Category Tile

## Configuration options

Category Tile component supports the following configuration options:

1. Component width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="--pd_category_tile-width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Aspect Ratio Crop

	Could be one of values: auto, 1:1, 3:4.

	How to use in code:
		CSS class "m-{aspect_ratio}"
		ex: "m-1_1", "m-auto", "m-3_4"

4. Title Top Margin

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="margin-top: var(--VALUE);"></div>]

5. Title Text Alignment

	Could be one of values: left, center, right

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="text-align: left/center/right;"></div>]

6. Button/Link inherits all the properties from Button/Link component and can be found under 05-blocks/page_designer/link_button section.

## Examples:

### Example 1

Tile width = 30%, Margin top = zero

Image Aspect Ratio 3:4 (default),

Title alignment- left, title top margin = M,

Button main alignment- left, button main top margin = M

```html_example
<a href="#"
    class="b-category_tile"
    style="
        --pd_category_tile-width: 30%;
        margin-top: var(--zero);
">
	<picture class="b-category_tile-picture m-ratio m-3_4">
		<source media="(min-width: 1441px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(max-width: 767px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<img class="b-category_tile-picture_image"
            src="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
	<div class="b-category_tile-title"
        style="
            margin-top: var(--m);
            text-align: left;
    ">
        <h3 class="t-heading_3">Lorem ipsum dolor sit amet</h3>
	</div>
	<span
		class="g-button_main"
		style="
			margin-top: var(--m);
			justify-self: start;
	">
		Link Copy
	</span>
</a>
```

### Example 2

Tile width = 30%, Margin top = zero

Image Aspect Ratio 1:1,

Title alignment- left, title top margin = M,

Link type 3 alignment- left, button main top margin = M

```html_example
<a href="#"
    class="b-category_tile"
    style="
        --pd_category_tile-width: 30%;
        margin-top: var(--zero);
">
	<picture class="b-category_tile-picture m-ratio m-1_1">
		<source media="(min-width: 1441px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(max-width: 767px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<img class="b-category_tile-picture_image"
            src="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x"
            alt="Alternative text"
            title="Image title"
        />
	</picture>
	<div class="b-category_tile-title"
        style="
            margin-top: var(--m);
            text-align: center;
    ">
        <h3 class="t-heading_3">Lorem ipsum dolor sit amet</h3>
	</div>
	<span
		class="t-link_3"
		style="
			margin-top: var(--m);
			justify-self: center;
	">
		Link Copy
	</span>
</a>
```

### Example 3

Tile width = 30%, Margin top = zero

Image Aspect Ratio- auto,

Title alignment- left, title top margin = M,

Button alt alignment- left, button main top margin = M

```html_example
<a href="#"
	class="b-category_tile"
	style="
		--pd_category_tile-width: 30%;
		margin-top: var(--zero);
">
	<picture class="b-category_tile-picture m-auto">
		<source media="(min-width: 1441px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<source media="(max-width: 767px)" srcset="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x">
		<img class="b-category_tile-picture_image"
			src="https://placehold.it/600x800?text=Category+image, https://placehold.it/600x800?text=Category+image 2x"
			alt="Alternative text"
			title="Image title"
		/>
	</picture>
	<div class="b-category_tile-title"
		style="
			margin-top: var(--m);
			text-align: right;
	">
		<h3 class="t-heading_3">Lorem ipsum dolor sit amet</h3>
	</div>
	<span
		class="g-button_alt"
		style="
			margin-top: var(--m);
			justify-self: end;
	">
		Link Copy
	</span>
</a>
```

*/

.b-category_tile {
	display: grid;
	width: var(--pd_category_tile-width);

	@include media(md-up) {
		padding: rh(10 6);
	}

	@include media(sm) {
		width: 100%;
	}

	&:hover {
		text-decoration: none;
	}

	&-picture {
		&.m-ratio {
			padding-bottom: calc(var(--aspect-height, 3) / var(--aspect-width, 4) * 100%);
			position: relative;
		}

		&.m-1_1 {
			--aspect-height: 1;
			--aspect-width: 1;
		}

		&.m-3_4 {
			--aspect-width: 3;
			--aspect-height: 4;
		}
	}

	&-picture.m-auto &-picture_image {
		width: 100%;
	}

	&-picture.m-ratio &-picture_image {
		height: 100%;
		left: 0;
		object-fit: cover;
		object-position: var(--pd_banner-focal_x, 50%) var(--pd_banner-focal_y, 50%);
		position: absolute;
		width: 100%;
	}

	&-title {
		word-break: break-word;
	}
}
