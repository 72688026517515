/*md

# List component

Component for styling ordered or unordered lists

List items are also styled.

```scss_example
.b-tips {
	@include t-list;
}

.b-tips {
	@include t-list(ordered);
}
```

*/

@mixin t-list($_mod: unordered) {
	@if $_mod == unordered {
		list-style: disc inside;
	}

	@if $_mod == ordered {
		list-style: decimal inside;
	}

	li {
		font-size: 16px;
		letter-spacing: 0.2px;
		line-height: 24px;
	}
}
