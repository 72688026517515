.b-suggestions_category {
	text-decoration: none;

	&-title {
		font-weight: bold;
	}

	&:hover,
	&.m-focused {
		text-decoration: underline;
	}

	&-parent_title {
		font-weight: normal;
	}
}
