/*md

# Banner Type 1

## Configuration options

Banner Type 1 supports the following configuration options:

1. Banner width.

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="width: NN%;"]

2. Banner Minimum Height.

	Could be one of values: auto, 100%, 80%, 50%, 30%.

	How to use in code:
		inline CSS [style="--pd_banner-min_height: {VALUE};"]

3. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

4. Banner Horizontal paddings

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="--pd_banner-padding-horizontal: var(--VALUE);"]

5. Banner Vertical paddings

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="--pd_banner-padding-vertical: var(--VALUE);"]

6. Banner Background Color

	How to use in code:
		inline CSS [style="background-color: var(--VALUE);"]

7. Region Width

	Could be one of values: 100%, 90%, 80%, 50%, 30%

	How to use in code:
		inline CSS [style="--pd_banner-region_width: NN%;"]

8. Region Horizontal Alignment

	Could be one of values: left, center, right

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="align-self: start/center/end;"></div>]

9. Region Vertical Alignment

	Could be one of values: top, middle, bottom

	How to use in code:
		inline CSS [<div class="b-category_tile-title" style="justify-self: start/center/end;"></div>]

## Examples:

### Example 1

Banner with background image.

Banner width = 80%, min height = 80% of screen, margin top = L, horizontal padding = XL, vertical padding = L

Region width = 50%, horizontal alignment = center, vertical alignment = middle

Region Content: Textbox + Button Alternative (small, inversed)

```html_example
<div class="b-banner_1" style="
        width: 80%;
        margin-top: var(--l, 0);
        --pd_banner-min_height: 80vh;
        --pd_banner-padding-horizontal: var(--xl);
        --pd_banner-padding-vertical: var(--l);
">
    <picture class="b-banner_1-background">
        <source media="(min-width: 1441px)" srcset="https://placehold.it/1900x600, https://placehold.it/1900x600 2x">
        <source media="(min-width: 1200px) and (max-width: 1440px)" srcset="https://placehold.it/1440x600, https://placehold.it/1440x600 2x">
        <source media="(min-width: 768px) and (max-width: 1199px)" srcset="https://placehold.it/1200x600, https://placehold.it/1200x600 2x">
        <source media="(max-width: 767px)" srcset="https://placehold.it/767x600, https://placehold.it/767x600 2x">
        <img class="b-banner_1-background_image"
            src="https://placehold.it/767x600, https://placehold.it/767x600 2x"
            alt="Alternative text"
            title="Image title"
        />
    </picture>
    <div style="--pd_banner-region_width:50%; align-self:center; justify-self:center;" class="b-banner_1-region">
        <div class="b-banner_1-region_inner">
            <div class="b-text_box" style="
                    --component-width: 100%;
                    margin-top: var(--zero, 0);
                    justify-self: center;
                    text-align: center;
            ">
                <h4 class="t-heading_4">Lorem ipsum dolor sit amet</h4>
                <p class="t-paragraph_2">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <div class="b-banner_1-region_inner">
            <a href="#" target="_blank"
                class=" g-button_alt m-inversed m-small "
                style=" margin-top: var(--m, 0); justify-self: center;
            ">
                Link Copy
            </a>
        </div>
    </div>
</div>
```

### Example 2

Banner with background color

Banner width = 80%, min height = 30% of screen, margin top = L, horizontal padding = XL, vertical padding = L

Region width = 50%, horizontal alignment = left, vertical alignment = top

Region Content: Textbox + Button Alternative (small, inversed)

```html_example
<div class="b-banner_1" style="
        width: 80%;
        justify-self: center;
        margin-top: var(--l, 0);
        background-color: lightblue;
        --pd_banner-min_height: 30vh;
        --pd_banner-padding-horizontal: var(--xl);
        --pd_banner-padding-vertical: var(--l);
">
	<div style="--pd_banner-region_width:50%; align-self:start; justify-self:start;"
		class="b-banner_1-region
	">
        <div class="b-banner_1-region_inner">
            <div class="b-text_box" style="
                    --component-width: 100%;
                    margin-top: var(--zero, 0);
                    justify-self: center;
                    text-align: center;
            ">
                <h4 class="t-heading_4">Lorem ipsum dolor sit amet</h4>
                <p class="t-paragraph_2">Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </div>
        </div>
        <div class="b-banner_1-region_inner">
            <a href="#" target="_blank"
                class=" g-button_alt m-inversed m-small "
                style=" margin-top: var(--m, 0); justify-self: center;
            ">
                Link Copy
            </a>
        </div>
    </div>
</div>
```

*/

.b-banner_1 {
	display: grid;
	min-height: var(--pd_banner-min_height);
	padding: var(--pd_banner-padding-vertical) var(--pd_banner-padding-horizontal);
	position: relative;
	width: var(--pd-component_width, 100%);

	@include media(sm) {
		padding-left: var(--s);
		padding-right: var(--s);
		width: 100%;
	}

	&-background {
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&-background_image {
		height: 100%;
		object-fit: cover;
		object-position: var(--pd_banner-focal_x, 50%) var(--pd_banner-focal_y, 50%);
		pointer-events: none; // disable image scale for IOs v13
		position: absolute;
		top: 0;
		width: 100%;
	}

	&-region {
		color: $color-banner_1_region;
		z-index: 1;
		align-self: var(--mobileAlign);
		justify-self:var(--mobileJustify);
		@include media(md-up) {
			width: var(--pd_banner-region_width);
			align-self: var(--desktopAlign);
			justify-self:var(--desktopJustify);
		}
		&.has-mobile-region{
			display: none;
			@include media(md-up) {
				display: block;
			}
		}
		&.mobile-show{
			display: block;
			@include media(md-up) {
				display: none;
			}
		}
	}

	&-region_inner {
		display: grid;
	}

    &.has-mobile {
		.b-banner_1-backgroundmobile {
			display: none;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
			@include media(sm) {
				display: block;
			}
		}

		.b-banner_1-background {
			@include media(sm) {
				display: none;
			}
		}
	}

}
