/*md
# Product Promo

## Default state

```html_example
	<div class="b-product_promo">Today only! Buy 6 bottles and get 20% OFF!<a class="b-product_promo-link" href="#">Details</a></div>
```
*/

.b-product_promo {
	@include g-promo {
		padding: rh(2 4);
		text-align: left;
	}

	@include media(md) {
		font-size: 12px;
	}

	&-link {
		@include t-link_2;

		cursor: pointer;
		font-size: $configuration-product_promo-link-font_size;
		font-weight: $configuration-product_promo-link-font_weight;
		margin-left: rh(2);
	}

	&.m-small {
		font-size: 13px;
		padding: rh(1 2);
	}
}
