/*md

# 75/25 Container

75/25 Container supports 2 layout cells:

	* First cell covers 75% of the viewport width on M/L/XL viewports
	* Second cell covers 25% of the viewport width on M/L/XL viewports.
	* Stacked on S viewports

## Configuration options

75/25 Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Custom gutter (small viewport only)

	One of S-XXL values OR zero.

	How to use in code:
		inline CSS [style="--pd-grid_gap-sm: var(--VALUE);"]

4. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-75_25-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL; default gutter;

```html_example
<div class="b-75_25 m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 2

Width = full-width; Top margin = S; custom gutter = S;

```html_example
<div class="b-75_25 m-full_width" style="margin-top: var(--s); --pd-grid_gap-sm: var(--s);">
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 3

Width = 60%; Top margin = zero; default custom gutter;

Column 1 vertical alignment = center

```html_example
<div class="b-75_25" style="--pd-layout_width: 60%; margin-top: var(--zero);">
	<div class="b-75_25-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?text=Cell+A" alt="Image" />
	</div>
	<div class="b-75_25-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

*/

.b-75_25 {
	@include g-pd_layout(wrapper);

	grid-template-columns: 3fr 1fr;

	@include media(sm) {
		grid-template-columns: 1fr;
	}

	&-cell_inner {
		@include g-pd_layout(cell);
	}
}
