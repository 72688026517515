.b-footer_info {
	&-title {
		@include t-heading_6;

		margin-bottom: rh(3);

		@include media(sm) {
			margin-bottom: rh(4);
		}
	}

	&-content {
		font: 16px/24px $font-main;
	}

	&-text {
		margin-bottom: rh(3);

		@include media(sm) {
			margin-bottom: rh(4);
		}
	}

	&-link {
		@include t-link_5(
			$color: var(--color-footer-text, $color-footer-text),
			$color-visited: $color-footer-text
		);

		display: inline-block;
		font-size: 16px;
		line-height: 24px;
		margin-top: rh(3);

		@include media(sm) {
			&.m-info {
				@include t-link_2($color: $color-footer-text);
			}
		}
	}

	&-text &-link {
		margin: 0;
	}
}
