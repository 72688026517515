/*md

# 2 Column Container

2 Column Container supports 2 layout cells:

	* half and half of the viewport width on M/L/XL viewports
	* stacked on S viewports

## Configuration options

2 Column Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%, 50%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Custom gutter (small viewport only)

	One of S-XXL values OR zero.

	How to use in code:
		inline CSS [style="--pd-grid_gap-sm: var(--VALUE);"]

4. Cell order (small viewport only)

	Layout cells order can be changed from A-B to B-A on small viewport

	How to use in code:
		by CSS class "m-swap"

5. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-2_up-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL; default gutter; cells order on mobile = A-B

```html_example
<div class="b-2_up m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Example 2

Width = full-width; Top margin = XL; custom gutter = S; cells order on mobile = B-A

```html_example
<div class="b-2_up m-full_width m-swap" style="margin-top: var(--xl); --pd-grid_gap-sm: var(--s);">
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Cell+A" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

### Example 3

Width = 60%; Top margin = XXL; default custom gutter; cells order on mobile = B-A; column 1 vertical alignment = center

```html_example
<div class="b-2_up m-swap" style="--pd-layout_width: 60%; margin-top: var(--xxl);">
	<div class="b-2_up-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?text=Cell+A" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Cell+B" alt="Image" />
	</div>
</div>
```

*/

.b-2_up {
	@include g-pd_layout(wrapper);

	grid-template-columns: 1fr 1fr;

	@include media(sm) {
		grid-template-columns: 1fr;
	}

	&.m-swap &-cell {
		@include g-pd_layout(swap);
	}

	&-cell_inner {
		@include g-pd_layout(cell);
	}
}
