/*md

# f-input_password

Default input element

## Default input

```scss_example
	.f-input_password {
		@include f-input_password($style: default);
	}
```
*/

@mixin f-input_password($_mod: default) {
	@if $_mod == default {
		.f-input_password {
			position: relative;

			&-field {
				@include f-input_default(default);

				border: 1px solid $color-form-input-border;
				padding: 21px 55px 7px 15px;
				text-transform: none;

				@include media(sm) {
					padding: 28px 55px 10px 15px;
				}

				&:hover {
					@include f-input_default(focused);
				}

				&.m-invalid {
					@include f-input_default(invalid);
				}

				&:disabled {
					@include f-input_default(disabled);
				}

				&:focus::placeholder {
					color: $color-form-label;
				}
			}

			&-label {
				@include f-input_default($el: label);
			}

			&-toggle_visibility {
				@include t-link_5($font-weight: 600);

				cursor: pointer;
				font-size: 12px;
				position: absolute;
				right: rh(3);
				top: rh(3);

				@include media(sm) {
					top: rh(4);
				}
			}

			&.m-required &-label {
				@include f-label(required);
			}

			&-field:not(:placeholder-shown) ~ &-label,
			&-field:focus ~ &-label {
				transform: translateY(-40%) scale(0.75);
			}

			&-field:disabled ~ &-label {
				color: $color-form-input__disabled;
			}
		}
	}
}
