/*md

# Global messages

## Info message

```html_example
<div class="b-message">
	Your wish list is only available on this device and expire after 7 days.
	Sign in or Create account to keep your items always at hand.
</div>
```

## Promo message

```html_example
<div class="b-message m-promo">
	Today only! Buy 6 bottles and get 20% OFF
</div>
```

## Error message

```html_example
<div class="b-message m-error">
	Oops! It looks like some of the items below are no longer available.
	Please update your shopping cart to continue
</div>
```

*/

.b-message {
	@include g-message;

	&.m-promo {
		@include g-message(promo);
	}

	&.m-error {
		@include g-message(error);
	}

	&-accent_text {
		font-weight: 700;
	}

	&-link {
		@include t-link_underlined;
	}
}
