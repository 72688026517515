.b-dialog {
	@include g-dialog_backdrop;

	--dialog-z: #{z(modal)};

	&.m-opened {
		@include g-dialog_backdrop(active);
	}

	&-window {
		background-color: $color-dialog-background;
		margin-bottom: auto;
		max-width: 960px;
		opacity: 0;
		padding: rh(12 12 14);
		position: relative;
		transform: scale(0.9);
		transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
		visibility: hidden;
		width: auto;

		@include media(md-up) {
			margin-top: auto;
		}

		@include media(md) {
			padding: rh(10 10 12);
		}

		@include media(sm) {
			padding: rh(8 8 10);
		}
	}

	&.m-opened &-window {
		opacity: 1;
		transform: scale(1);
		visibility: visible;
	}

	&-title {
		@include t-heading_2;

		margin-bottom: rh(6);
	}

	&-body {
		font-size: 18px;
		line-height: 26px;
	}

	&-note {
		font-size: 18px;
		letter-spacing: 0.2px;
		line-height: 26px;
		margin-bottom: rh(4);
	}

	&-note.m-bold {
		font-weight: 700;
	}

	.b-button_close {
		position: absolute;
		right: 15px;
		top: 15px;

		@include media(sm) {
			right: 0;
			top: 0;
		}
	}

	&-footer {
		margin-top: rh(6);

		@include media(md-up) {
			display: flex;
			justify-content: space-between;
			margin-top: rh(8);
		}
	}

	&-footer.m-center {
		@include media(md-up) {
			justify-content: center;
		}
	}

	&-btn {
		width: 100%;

		@include media(md-up) {
			flex: 1 1 100%;
			width: auto;
		}
	}

	&-btn + &-btn {
		margin-left: rh(4);

		@include media(sm) {
			margin: rh(4 0 0 0);
		}
	}

	&-btn.m-main {
		@include g-button(main);
	}

	&-btn.m-alt {
		@include g-button(alt);
	}

	&-btn:first-child:last-child {
		@include media(md-up) {
			flex: 0 0 auto;
		}
	}

	&-reject {
		@include g-button(alt, default full_width);
	}

	&-confirm {
		@include g-button(main, default full_width);

		@include media(sm) {
			margin-top: rh(4);
		}

		@include media(md-up) {
			margin-left: rh(3);
		}
	}

	&.m-quick_view &-window {
		$quick-view-modal-indent-right: 22;

		@include media(lg-up) {
			max-width: 100%;
			padding: rh(0 $quick-view-modal-indent-right 12 7);
			width: 1264px;
		}
	}

	&.m-search_suggestions {
		--dialog-z: #{z(search)};

		align-items: flex-start;
		animation: fade-in $motion-ease;
		overflow-y: auto;
		padding: 0;

		&::after {
			touch-action: none;
		}

		&:not(.m-opened) {
			display: none; // focus in input will not be setted second time for visible element
		}
	}

	&.m-reset_password &-window,
	&.m-promotion &-window,
	&.m-browsing_only &-window,
	&.m-remove_product &-window {
		min-width: auto;
		width: 680px;
		margin-top: auto;

		@include media(md) {
			width: 630px;
		}
	}
	&.m-browsing_only{
		.b-dialog-footer{
			flex-wrap: wrap;
			.b-dialog-btn{
				flex: 1 1 48%;
			}
			
		}
	}
	&.m-delete_address &-window {
		max-width: 100%;
		min-width: auto;
		width: 524px;
	}
}
