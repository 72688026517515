/*md

# Link type 2

Fully inherits `t-link_underlined` component
with some modifications.

It takes the same list of arguments as `t-link_underlined`
component
```scss_example
.b-some_link {
	@include t-link_2;
}
```
*/

@mixin t-link_2($args...) {
	@include t-link_underlined($font-weight: bold, $underline-offset: $configuration-link_2-underline_offset, $args...);
}
