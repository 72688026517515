// Base global styles
// Should be included only once

/* stylelint-disable selector-max-universal */
*,
*::before,
*::after {
	box-sizing: inherit;
}
/* stylelint-enable */
a {
	background-color: transparent;
}

ul,
ol,
p {
	list-style: none;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	margin: 0;
}

figure {
	margin: 0;
}

img {
	border: none;
	height: auto;
	max-width: 100%;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}

button,
input,
select,
textarea {
	color: inherit;
	font: inherit;
}

input::-ms-reveal,
input::-ms-clear {
	display: none;
}

[hidden] {
	display: none !important; // stylelint-disable-line
}
