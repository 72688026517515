/*md
@no-stat

# Hide

This mixin is especially useful for hiding text
or visually hide needed elements

Here is a list of parameters you can use:

* text - helps to hide text without loosing visibility for parsers
* visually - like for text but for the whole element

Second parameter is for the `!important` keyword.

## Usage

```scss
.component {
	@include hide(visually);
}

.h-hide_vis {
	@include hide(visually, true);
}
```
*/

@mixin hide($type, $important: false) {
	$__important: if($important == true, '!important', ''); // stylelint-disable-line

	@if $type == text {
		background-color: transparent unquote(#{$__important});
		color: transparent unquote(#{$__important});
		font-size: 0 unquote(#{$__important});
		letter-spacing: 0 unquote(#{$__important});
		line-height: 0 unquote(#{$__important});
		text-shadow: none unquote(#{$__important});
	}

	@if $type == visually {
		border: 0 unquote(#{$__important});
		clip: rect(1px, 1px, 1px, 1px) unquote(#{$__important});
		margin: 0 unquote(#{$__important});
		max-height: 1px unquote(#{$__important});
		max-width: 1px unquote(#{$__important});
		overflow: hidden unquote(#{$__important});
		padding: 0 unquote(#{$__important});
		position: absolute unquote(#{$__important});
	}
}
