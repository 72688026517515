/*md

# 50/50 Container

50/50 Container supports 2 layout cells (equal width)

## Configuration options

50/50 Container supports the following configuration options:
1. Content width.

	Could be one of values: grid-width, full-width, 80%, 60%, 50%.

	How to use in code:
		grid-width --> CSS class "m-grid_width"
		full-width --> CSS class "m-full_width"
		%% value   --> inline CSS [style="--pd-layout_width: NN%;"]

2. Top margin.

	One of XXS-XXXL values OR zero.

	How to use in code:
		inline CSS [style="margin-top: var(--VALUE);"]

3. Cell vertical alignment

	How to use in code:
		inline CSS [<div class="b-2_up-cell" style="align-self: start/center/and;"> </div>]

## Examples:

### Example 1

Width = grid-width; Top margin = XXL;

```html_example
<div class="b-2_up m-grid_width" style="margin-top: var(--xxl);">
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Example 2

Width = full-width; Top margin = M;

```html_example
<div class="b-2_up m-full_width" style="margin-top: var(--m);">
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
	<div class="b-2_up-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

### Example 3

Width = 80%; Top margin = XXL; column 1 vertical alignment = center

```html_example
<div class="b-50_50" style="--pd-layout_width: 60%; margin-top: var(--xxl);">
	<div class="b-50_50-cell" style="align-self: center;">
		<img src="https://placehold.it/900x400?text=Layout+content" alt="Image" />
	</div>
	<div class="b-50_50-cell">
		<img src="https://placehold.it/900x600?text=Layout+content" alt="Image" />
	</div>
</div>
```

*/

.b-50_50 {
	@include g-pd_layout(wrapper);

	grid-template-columns: 1fr 1fr;

	&-cell_inner {
		@include g-pd_layout(cell);
	}
}
