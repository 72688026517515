.flip-card{
    color: var(--textColor);
    perspective: 100rem;
    height: 100%;

    &:hover, &:focus{
        .flip-card-inner{
            transform: rotateY( -180deg ) ;
            transition: transform 0.5s;
        }
    }

    .flip-card-inner{

        height: 100%;
        display: flex;
        box-shadow: 0 0 15px rgba(0,0,0,0.1);
        transition: transform 1s;
        transform-style: preserve-3d;

        .flip-card-front, .flip-card-back{

            min-width: 100%;
            text-align: center;
            backface-visibility: hidden;
            background-color: var(--bgColor);
        }

        .flip-card-back{
            transform: rotateY(-180deg) translate(100%, 0);
            &--inner{
                padding: 100px 20px 20px;
            }
        }
    }

    hr{
        border-style: solid;
    }
}