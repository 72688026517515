.b-newsletters {
	@include f-input_text($_mod: alt);

	&-group {
		position: relative;
	}

	&-input {
		border-color: $color-footer_newsletter-border;
		color: $color-footer-text;
		font-size: 12px;
		font-weight: bold;
		line-height: 24px;
		text-transform: uppercase;

		&.m-invalid {
			color: $color-footer-text;
		}
	}

	&-submit {
		@include icon(line-arrow, $icon-width: 50px);

		background: none;
		border: 0;
		cursor: pointer;
		height: 15px;
		padding: 0;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		transition: top linear 0.5s;

		&:hover {
			background: none;
		}
	}

	&-message_success {
		border-bottom: 1px solid $color-footer_newsletter-border;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 1.8px;
		line-height: 24px;
		margin-bottom: rh(5);
		padding: rh(2 0);
		position: relative;
		text-align: center;
		text-transform: uppercase;

		@include icon(checkmark, $icon-width: 15px, $icon-height: 15px) {
			display: inline-block;
			margin-right: 8px;
			vertical-align: text-top;
		}
	}

	.f-input_text {
		&-label {
			color: var(--color-footer-text, $color-footer-text);
			line-height: 24px;
			top: 10px;
		}

		&-field {
			padding: 21px 60px 7px 0;
		}

		&-field::placeholder,
		&-field:focus::placeholder {
			color: transparent;
		}

		&-field,
		&-field.m-invalid {
			color: $color-footer-text;
		}

		&-field.m-focused,
		&-field:hover {
			border-color: $color-footer_newsletter-border;
		}
	}

	.f-field-error {
		background: $color-newsletter-error-background;
		font-size: 12px;
		font-weight: bold;
		line-height: 16px;
		margin-top: 4px;
		padding: rh(2 0 2 7);
		position: absolute;
		top: 100%;
		width: 100%;

		@include icon(error, $icon-width: 17px, $icon-height: 15px) {
			left: 10px;
			position: absolute;
		}
	}
}
