.b-search_dialog {
	height: 100%;
	opacity: 0;
	transition: opacity $motion-ease;
	width: 100%;
	z-index: 2;

	&.m-active {
		opacity: 1;
	}

	&::after {
		background-color: $color-search_panel-suggestions-background;
		bottom: 0;
		content: '';
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		transition: visibility 0.5s, opacity 0.5s;
		visibility: hidden;
		z-index: 1;
	}

	&.m-active_suggestions::after {
		opacity: 1;
		visibility: visible;
	}

	&-inner {
		background-color: transparent;
		background-image: $color-search_panel-background;
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: rh(12);
		position: relative;
		transition: background-color 0.3s;
		width: 100%;
		z-index: z(search);

		@include media(sm) {
			background: $color-search_panel-mobile-background;
			padding: rh(10 7 0);
		}
	}

	&-inner_top_content {
		margin: 0 auto;
		max-width: $size-search_panel-content-max_width;
	}

	&-form_wrap {
		align-items: center;
		display: flex;
		justify-content: center;
		margin-bottom: rh(10);
		width: 100%;

		@include media(md-up) {
			margin-bottom: rh(12);
		}
	}

	.b-button_close {
		position: absolute;
		right: 0;
		top: 0;

		@include media(md-up) {
			right: 15px;
			top: 15px;
		}
	}
}
