/*md

# f-label

Default label element

## Default label

```scss_example
	.f-input_text-label {
		@include f-label($style: default);
	}
```
*/

@mixin f-label($_mod: default) {
	@if $_mod == default {
		color: $color-form-label;
		cursor: pointer;
		display: flex;
		font: $configuration-global_label-font;
		letter-spacing: $configuration-global_label-letter_spacing;
		text-transform: $configuration-global_label-text_transform;
		transform-origin: left top;
		transition: transform $motion-regular;
	}

	@if $_mod == required {
		&::after {
			content: '*';
		}
	}
}
