/*md
# Expandable block

## Default state

```html_example
	<div class="b-expandable m-collapsed">
		<div class="b-expandable-content">
			Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi,
			quasi voluptatum! Maxime magni totam ipsa unde enim maiores perferendis.
			Officiis temporibus impedit amet aliquam dolore corrupti mollitia,
			soluta beatae perspiciatis optio saepe, autem delectus a quos dolor
			quisquam porro! Enim inventore tempora facilis atque possimus deleniti
			quia vel omnis nisi quo, saepe hic suscipit voluptas ratione accusamus
		</div>
		<button class="b-expandable-link">
			<span class="b-expandable-link_text m-more">View more</span>
			<span class="b-expandable-link_text m-less">View less</span>
		</button>
	</div>
```
*/

.b-expandable {
	&-content {
		max-height: 1000px;
		overflow: hidden;
		position: relative;
		transition: max-height 0.5s ease;
	}

	&-link {
		@include t-link_1;

		cursor: pointer;
		font-size: 16px;
		margin-top: rh(4);
	}

	&-link_text.m-more {
		display: none;
	}

	&.m-collapsed &-link_text {
		&.m-more {
			display: block;
		}

		&.m-less {
			display: none;
		}
	}

	&.m-collapsed &-content {
		max-height: var(--collapsed-height);
	}

	// Types
	&.m-product_description {
		.b-product_description-upc,
		.b-product_description-id {
			max-height: 50px;
			transition: 500ms ease;
			transition-property: margin, max-height;
		}
	}

	&.m-product_description.m-collapsed {
		--collapsed-height: 190px;

		@include media (sm) {
			--collapsed-height: 237px;
		}

		.b-product_description-text {
			@include lines-clamp(6);

			@include media (sm) {
				@include lines-clamp(8);
			}
		}

		.b-product_description-upc,
		.b-product_description-id {
			margin: 0;
			max-height: 1px;
			visibility: hidden;
		}
	}

	&.m-bundled-products.m-collapsed {
		--collapsed-height: 0;
	}
}
