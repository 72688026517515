.b-search_input {
	align-items: center;
	border-bottom: 1px solid $color-primary;
	display: flex;
	position: relative;
	width: 100%;

	&-input {
		@include f-input_reset;

		color: $color-primary;
		flex-grow: 1;
		font-family: $font-alt;
		font-size: 32px;
		line-height: 40px;
		order: 1;
		padding: rh(0 0 2 0);
		width: auto;

		@include media(md-up) {
			font-weight: $configuration-search_input-placeholder-font_weight;
		}

		@include media(sm) {
			width: 100%;
		}

		&::placeholder {
			color: $color-primary;
			text-transform: capitalize;
		}

		&::-webkit-search-cancel-button,
		&::-webkit-search-decoration,
		&::-webkit-inner-spin-button {
			appearance: none;
		}

		&::-ms-clear {
			display: none;
		}
	}

	&-submit {
		@include icon(search, $icon-width: 16px, $icon-height: 16px);

		align-self: stretch;
		cursor: pointer;
		order: 3;
		padding-left: rh(4);
	}

	&-clear {
		cursor: pointer;
		font-weight: bold;
		opacity: 0;
		order: 2;
		pointer-events: none;
		transition: opacity 0.3s;

		&.m-visible {
			opacity: 1;
			pointer-events: all;
		}
	}
}
