.b-suggestions {
	flex-grow: 1;
	letter-spacing: 0.7px;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;

	@include media(md-up) {
		margin: 0 auto;
		max-width: 100%;
		width: calc(#{$size-search_panel-content-max_width} + #{$configuration-highlighter-outline_width}); // to avoid outline cropping
	}

	// Layout
	&-inner {
		display: grid;

		@include media(md-up) {
			grid-template-columns: repeat(3, 1fr);
			margin: rh(0 -6);
			padding: 0 $configuration-highlighter-outline_width;
		}
	}

	&-section {
		display: flex;
		flex-direction: column;

		@include media(md-up) {
			margin: rh(0 6);
		}

		&.m-guess {
			display: flex;
			flex-direction: column;
			margin-bottom: rh(1);
			margin-top: $configuration-highlighter-outline_width;

			@include media(md-up) {
				grid-area: 1 / 1 / 2 / 4;
			}
		}

		&.m-view_all {
			@include media(md-up) {
				grid-area: 3 / 1 / 4 / 4;
			}
		}

		&.m-no_results {
			font-size: 18px;
			margin-bottom: rh(8);

			@include media(md-up) {
				grid-area: 1 / 1 / 2 / 4;
			}
		}
	}

	&-guess_item {
		margin-bottom: rh(1);

		@include hover-supported {
			&:hover {
				text-decoration: underline;
			}
		}

		&:last-child {
			margin-bottom: rh(6);

			@include media(md-up) {
				margin-bottom: rh(9);
			}
		}
	}

	&-guess_correction {
		font-weight: bold;
		text-transform: uppercase;
	}

	// Content
	&-title {
		font-weight: bold;
		margin-bottom: rh(5);
	}

	&-section.m-products &-title {
		@include media(sm) {
			margin-bottom: rh(4);
		}
	}

	&-option {
		margin-bottom: rh(5);
	}

	&-option.m-focused {
		@media (hover: none) and (pointer: coarse) {//TODO: check with Kolya. Is it still needed after m-focused -> h-focused
			outline: none !important; /* stylelint-disable-line declaration-no-important */
		}
	}

	&-section.m-products &-option {
		@include media(md-up) {
			margin-bottom: rh(3);
		}
	}

	&-section.m-products,
	&-section.m-categories,
	&-section.m-content {
		@include media(sm) {
			border-bottom: 1px solid $color-suggestions_section-mobile-divider;
			margin-bottom: rh(5);
		}
	}

	&-message {
		@include hide(visually);
	}

	&-view_results {
		align-items: center;
		display: flex;
		font-weight: bold;
		letter-spacing: 3px;
		margin-top: rh(7);
		text-transform: uppercase;

		@include icon(line-arrow, $icon-width: 51px, $icon-height: 12px, $position: after) {
			margin-left: rh(2);
		}

		@include media(sm) {
			margin-top: rh(3);
		}
	}

	.b-product_price {
		font-size: 12px;
	}

	&-categories-list, &-content-list {
		display: flex;
		flex-direction: column;
	}
}
