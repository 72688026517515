.b-payment_total {
	@include t-paragraph_1;

	font-weight: 700;

	&-item {
		display: flex;

		@include media(sm) {
			justify-content: space-between;
		}
	}

	&-value {
		margin-left: rh(4);
	}

	&-tax {
		color: $color-text-dimmed;
		font: 600 13px/1.5 $font-main;
		margin-top: rh(1);
	}
}
