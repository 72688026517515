/*md
@no-stat

# Icons from SVG sprite (example)

`icon` mixin designed to insert an icon for an element, `::before` or `::after` pseudo-elements.
This technique is based on SVG CSS masks over a background color.

Also there's a possibility to pre-load SVG icons (multiple images for `mask` property) in order
to mitigate a bug when usage of an SVG image from the sprite causes additional request for the sprite
image.

## Arguments

This mixin takes 4 arguments:

* icon name (ID attribute of a <symbol/> tag)
* icon width (default - 1em)
* icon height (default - 1em)
* position:
	* before (default)
	* after
* show:
	* root - applies icon for current selector
	* pseudo - applies icon for a pseudo element (default)
	* integer value - will change the mask size in order to switch preloaded icons

Also you can put a `@content` block for this mixin

To add an icon:

* you have to manually add SVG to './svg-icons/' folder
* run `gulp icons`
* after this build you'll be able to use icons from isml files:
	* `<isinclude template="icons/{icon-name}" />`
		* you can change icon class name (for SVG element),
		width and height using `<isset/>` before `<isinclude />`
		* `<isset name="iconHeight" value="20">`
		* `<isset name="iconWidth" value="20">`
		* `<isset name="iconClass" value="b-icon_class_name">`
	* `<img src="${URLUtils.staticURL('/svg-icons/{iconName}.svg')}" />`
	* `<img src="${URLUtils.staticURL('/svg-icons/sprite.svg#{iconName}')}" />`
* also there's a special tool you can use in content assets for inserting an icon
as inline SVG:
	* ` $tools.icon('bag', 20, 30, 'b-class_name')` where:
		* 'bag' - existing icon name
		* 20 - icon height (24 by default)
		* 30 - icon width (24 by default)
		* 'b-icon_class_name' - SVG element class name

To use an icon in SCSS:

```scss
@include icon(icon_name);
```

To use icons with pre-loading:

```scss
.test {
	@include icon(icon_name icon_name2); // will show the first icon

	&.m-active {
		@include icon(icon_name icon_name2, $show: 2); // will show the second icon
	}
}
```

Where icon name is a file name of individual SVG icon.
*/

@function mask-size($icon_list, $icon-number: 1) {
	$maskSize: ();

	@each $item in $icon_list {
		$value: 0;

		@if (index($icon_list, $item) == $icon-number) {
			$value: 100%;
		}

		$maskSize: append($list: $maskSize, $val: $value, $separator: comma);
	}

	@return $maskSize;
}

@function mask-url($icon_list) {
	$maskUrl: ();

	@each $item in $icon_list {
		$maskUrl: append($list: $maskUrl, $val: url('./svg-icons/sprite.svg##{$item}'), $separator: comma);
	}

	@return $maskUrl;
}

@mixin icon(
	$icon_name,
	$icon-width: 1em,
	$icon-height: 1em,
	$position: before,
	$show: pseudo
) {
	@if ($show == pseudo) {
		&::#{$position} {
			background: var(--icon-color, currentColor);
			content: '';
			display: block;
			height: $icon_height;
			mask: mask-url($icon_name);
			mask-position: 50%;
			mask-repeat: no-repeat;
			mask-size: mask-size($icon_name);
			width: $icon_width;

			@content;
		}
	}

	@if ($show == root) {
		background: var(--icon-color, currentColor);
		display: block;
		height: $icon_height;
		mask: mask-url($icon_name);
		mask-position: 50%;
		mask-repeat: no-repeat;
		mask-size: mask-size($icon_name);
		width: $icon_width;

		@content;
	}

	@if (type-of($show) == number) {
		mask-size: mask-size($icon_list: $icon_name, $icon-number: $show);
	}
}
