.b-button_close {
	@include icon(close, $icon-width: 21px, $icon-height: 21px);

	cursor: pointer;
	padding: rh(3);
	user-select: none;

	&::before {
		@include media(sm) {
			height: 16px;
			width: 16px;
		}
	}

	&.m-small {
		&::before {
			height: 16px;
			width: 16px;
		}
	}
}
