.b-back_to_top {
	color: $color-primary;
	cursor: pointer;
	display: block;
	text-align: center;

	@include media(lg-up) {
		bottom: 80px;
		display: flex;
		margin: 0 0 rh(6) auto;
		position: sticky;
		right: 50px;
		width: 48px;
		z-index: z(back-to-top);

		&.m-hide {
			opacity: 0;
			visibility: hidden;
		}
	}

	@include media(md-down) {
		@include g-section_holder;

		align-items: center;
		color: $color-link;
		flex-direction: column;
		font-weight: 700;
		letter-spacing: 3px;
		margin: rh(4 0 8);
		text-decoration: none;
		text-transform: uppercase;
		width: 100%;
	}

	&:hover {
		text-decoration: none;
	}

	&-icon {
		opacity: 0.5;
		transition: $motion-ease;
		transition-property: opacity, filter;

		@include media(md-down) {
			margin-bottom: rh(2);
			opacity: 1;
		}
	}

	&-icon_circle {
		fill: $color-back_to_top-icon-background;
	}

	&:hover &-icon {
		@media (hover: hover) and (pointer: fine) {
			filter: drop-shadow($depth-1-1) drop-shadow($depth-1-2);
			opacity: 1;
		}
	}

	&-copy {
		display: none;

		@include media(md-down) {
			display: block;
		}
	}
}
