.b-customer_service_info {
	font-size: 16px;
	line-height: 1.5;
	margin-left: 25px !important;
	a.b-customer_service_info-link {
		font-size: 11px !important;
	}
	&-title {
		@include t-heading_6;
	}

	&-title,
	&-msg {
		margin-bottom: rh(4);
	}

	&-item {
		margin-bottom: rh(3);

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-icon {
		display: inline-block;
		margin-right: rh(3);
		width: 30px;
	}

	&-link {
		@include t-link_5;
	}

	&.m-small &-title {
		letter-spacing: 0.2px;
		text-transform: none;
	}

	&.m-small &-icon {
		margin-right: rh(2);
		width: 18px;
	}

	&.m-center {
		text-align: center;
	}
}
