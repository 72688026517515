.m-promo--shipping{
    .meter {
    box-sizing: content-box;
    height: 17px; 
    position: relative;
    border-radius: 3px;
    border: 1.5px solid $shipping-promo-base;
    margin: 11px auto 0;
    
    span {
        display: block;
        height: 100%;
        border-radius: 0;
        background: $shipping-promo-progress;
        position: relative;
        overflow: hidden;
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        }

        &.unlocked{
            span{
                background: $shipping-promo-base;
                    border-top-right-radius: 1px;
                    border-bottom-right-radius: 1px;
            }
        }
    }

    .message{
        font: 16px/22px $font-main;
        letter-spacing: 2.34px;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 12px;
        text-transform: uppercase;
        &:before{
            content:'';
            width: 34px;
            height: 32px;
            -webkit-mask-image: url('svg-icons/free-shipping.svg');
            mask-image: url('svg-icons/free-shipping.svg');
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: cover;
            mask-size: cover;
            display: block;
            flex-shrink: 0;
            margin-left: 13px;
            background-color: $color-primary;
             @include media(md-down) {
                margin-left: 15px;
             }
        }
        &--link{
            font: normal normal normal 16px/22px $font-main;
            letter-spacing: 0.32px;
            a{
                text-decoration: underline;
                font-weight: bold;
            }
            &.unlocked{
                display: none;
                margin:0;
            }
        }
    }
}
// Cart Page modifications
.l-cart-inner{
    .l-cart-promo{
        display: flex;
        align-items: flex-end;
    &.desktop{
        display: flex;
        @include media(md-down) {
            display: none;
        }
    }
    &.mobile{
        display: none;
        @include media(md-down) {
            display: flex;
            justify-content: center;
            width: 100%;
            @include g-separator($mod: top, $color: $color-product_table-separator);
            @include g-separator($mod: bottom, $color: $color-product_table-separator);
            padding: 17px;
            .message{
                text-align: left;
            }
        }
    }
    }

    .m-promo--shipping{
        margin-left:65px;
        width: 100%;
        margin-bottom: 28px;

        @include media(md-down) {
            margin-left: 0;
            margin-bottom: 0;
            max-width: 355px;
        }
        @include media(sm) {
            max-width:unset;
        }
            
    }
}

// Checkout PAge modifications
.l-checkout-promo{
    margin-bottom: 32px;
    text-align: center;
        &.desktop{
        
        @include media(md-down) {
            display: none;
        }
    }
    &.mobile{
        display: none;
        @include media(md-down) {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 24px;
            @include g-separator($mod: bottom, $color: $color-product_table-separator);
            padding-bottom: 17px;
        }
    }
    .message--link{
        margin-top: 16px;
    }
}
// Mobile Cart Modifications
.b-minicart_panel-container{
    .m-promo--shipping{
        @include media(md-down) {
            @include g-separator($mod: top, $color: $color-product_table-separator);
            @include g-separator($mod: bottom, $color: $color-product_table-separator);
            padding: 17px 0; 
            margin-bottom: 16px;
        }
    }
}