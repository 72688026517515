.b-continue_shopping {
	align-items: center;
	cursor: pointer;
	display: inline-flex;
	font: 600 14px/1.5 $font-main;
	text-decoration: none;

	@include icon(arrow-left-thin, 8px, 14px, before) {
		margin-right: rh(2);
	}

	&:hover {
		text-decoration: none;
	}
}
