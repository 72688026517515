@import 'b-cart_shipping_promo';

.b-minicart {
	max-height: 100%;
	overflow-y: auto;
	padding: rh(6 6 0);
	@include media(md-down){
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-areas:
			"header"
			"messages"
			"content"
			"summary"
			"actions";
		padding: 30px 15px 0;	
	}

	&-header{
		@include media(md-down){
			grid-area: header;
		}
	}
	&-messages{
		@include media(md-down){
			grid-area: messages;
		}
	}
	&-summary{
		@include media(md-down){
			grid-area: summary;
			margin-bottom: 0;
		}
	}
	&-content{
		@include media(md-down){
			grid-area: content;
			height: 220px;
			overflow: scroll;
			.b-minicart-item{
				&:last-child{
					margin-bottom: 16px;
				}
			}
		}
	}
	&-actions{
		&.mobile{
			display: none;
			@include media(md-down){
				display: block;
				text-align:center;
				.b-minicart-checkout{
					width: auto;
					margin-bottom: 16px;
				}
				.b-minicart-view_cart{
					text-decoration: underline;
					color: $color-primary;
					display: block;
					font-weight: bold;
					&:after{
						display: none;
					}
				}
			}
		}
		@include media(md-down){
			grid-area: actions;
		}
	}


	&-title,
	&-item,
	&-summary,
	.b-message {
		margin-bottom: rh(6);
	}
	&-summary{
		.b-minicart-actions{
			@include media(md-down){
				display: none;
			}
		}
	}
	&-title {
		font: 26px/40px $font-alt;
		letter-spacing: 0.4px;
	}

	.b-message {
		padding: rh(4);
	}

	.b-payment_total {
		margin-bottom: rh(3);
		width: 100%;

		&-item {
			justify-content: space-between;
		}
		@include media(md-down){
			@include g-separator($mod: bottom, $color: $color-product_table-separator);
			padding-bottom: 16px;
		}
	}

	&-item {
		@include g-separator($color: $color-minicart_items-separator);
	}

	&-view_cart {
		@include t-link_3;

		display: inline-block;
		font-size: 16px;
		letter-spacing: 2.4px;
		margin-bottom: rh(4);
	}

	&-checkout {
		@include g-button(main, default full_width);
	}

	.b-button_close {
		position: absolute;
		right: 5px;
		top: 5px;

		&::before {
			height: 16px;
			width: 16px;
		}
	}
	
	.m-promo--shipping{
		margin-bottom: 28px;
	}
	&_panel-container{
		@include media(md-down){
			bottom: unset;
			top: 22px;
			left: 0; 
			right: 0; 
			margin: auto;
			max-width: 95%;
		}
	}
	&-ship_to{
		margin-left: 5px;
		text-decoration: underline;
		cursor: pointer;
	}
}
